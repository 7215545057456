export const PATHS = {
  home: '',
  userPortal: '/user-portal',
  portfolio: '/portfolio-manager',
  connectFi: '/connectFi',
  investmentPortal: '/project',
  launchpad: '/launchpad',
  launchPool: '/launch-pool',
  yieldbox: '/yieldbox',
  spend: '/spend',
  trading: '/trading',
  account: '/portfolio',
  settings: '/settings',
  news: '/news',
  guide: '/guide',
  whitepaper: '/whitepaper',
  register: '/launchpad/:id',
  farmingDetail: '/connectfi/:id',
}

export const labelNavbar = {
  home: 'Home',
  userPortal: 'User Portal',
  portfolio: 'Portfolio Manager',
  connectFi: 'ConnectFi',
  investmentPortal: 'Investment Portal',
  launchpad: 'Investment Portal',
  launchPool: 'Launch Pool',
  yieldBox: 'YieldBox',
  spend: 'SPEND',
  Trading: 'Trading',
  account: 'Account',
  settings: 'Settings',
  about: 'About',
  transparency: 'Transparency',
  apeActivator: 'Ape ctivator',
  news: 'News',
  guide: 'Guide',
  whitepaper: 'Whitepaper',
}
