import { useFetchAllLaunchesQuery } from '../../../services/modules/project'
import CompletedSales from '../../../pages/RevampedLaunchpad/CompletedSales'

export const CompletedSale = () => {
  const {
    data: allLaunchesData,
    isLoading: allLaunchesLoading,
    isError: allLaunchesError,
  } = useFetchAllLaunchesQuery()
  return <>
  <CompletedSales   tokenSales={
    allLaunchesData?.filter(
      (launch: any) => launch.type === 'completed',
    ) || []
  } /></>
}