//@ts-nocheck
import { NETWORKS_NAME } from "../../../utils/network";
import CheckIcon from "../../../anyfi-src/assets/icons/check_circle.svg";
import WalletConnectIcon from "../../../anyfi-src/assets/icons/wallet_connect_logo.svg";

const ConnectWalletBox = ({
  selectedNetwork,
  selectedConnector,
  handleConnectorClick,
  sortedConnectors,
  wallets
}) => {

  const walletConnectSolana = () => {
    return wallets
      .filter((wallet) => wallet.readyState === "Installed")
      .map((wallet) => {
        return (
          <div
            key={wallet.adapter.name}
            className={`list-network ${
              selectedConnector === wallet.adapter.name ? "selected" : ""
            }`}
            onClick={() => handleConnectorClick(wallet.adapter.name)}
          >
            <div className="walletBoxIconWrap">
              <img
                src={wallet.adapter.icon}
                alt={`${wallet.adapter.name} Icon`}
              />
              <p>{wallet.adapter.name}</p>
            </div>
            {selectedConnector === wallet.adapter.name && (
            <img src={CheckIcon} alt="CheckIcon" />
          )}
          </div>
        );
      });
  };

  const connectWaletWeb3 = () => {
    return sortedConnectors.map((connector, index) => {
      const isSelected = selectedConnector === connector.name;
      return (
        <div
          key={index}
          className={`list-network ${isSelected ? "selected" : ""}`}
          onClick={() => handleConnectorClick(connector.name)}
        >
          <div className="walletBoxIconWrap">
            {connector.name === "WalletConnect" ? (
              <img src={WalletConnectIcon} alt={`${connector.name} Icon`} />
            ) : (
              <img src={connector.icon} alt={`${connector.name} Icon`} />
            )}

            <p>{connector.name}</p>
          </div>
          {isSelected && <img src={CheckIcon} alt="CheckIcon" />}
        </div>
      );
    });
  };
  return (
    <>
      {selectedNetwork === NETWORKS_NAME.SOLANA
        ? walletConnectSolana()
        : connectWaletWeb3()}
    </>
  );
};
export default ConnectWalletBox;
