import {
  Box,
  Divider,
  Grid,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import {
  KeyMetrics1,
  KeyMetrics2,
  KeyMetrics3,
  KeyMetrics4,
  KeyMetrics5,
  KeyMetrics6,
  NavGreenArrow,
} from '../../assets'
import React, { useEffect, useState } from 'react'
import InfoBox from './InfoBox'
import KeyFeatureBox from './KeyFeatureBox'
import _ from 'lodash'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: '8px',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}))

const Heading: React.FC<{
  children: string
  as: 'h1' | 'h2'
  id?: string
}> = ({ children, id, as: Element, ...props }) => {
  const theId = id || getId(children)
  return (
    <Element
      id={theId}
      {...props}
      style={{
        fontFamily: 'Hellix',
        fontSize: '24px',
        fontWeight: 600,
        color: '#FFFFFF',
        margin: '0px',
        lineHeight: '31.2px',
        paddingBottom: '24px',
      }}
    >
      {children}
    </Element>
  )
}

type HeadingType = {
  id: string
  text: string
}
const useHeadings = () => {
  const [headings, setHeadings] = useState<HeadingType[]>([])
  useEffect(() => {
    const elements = Array.from(document.querySelectorAll('h1'))
      .filter(element => element.id)
      .map(element => ({
        id: element.id,
        text: element.textContent ?? '',
      }))
    setHeadings(elements)
  }, [])
  return headings
}

const getId = (children: string) => {
  return children
    .split(' ')
    .map(word => word.toLowerCase())
    .join('-')
}

const useScrollSpy = (ids: string[]) => {
  const [activeId, setActiveId] = useState<string>()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2

      for (let i = ids.length - 1; i >= 0; i--) {
        const section = document.getElementById(ids[i])

        if (section && section.offsetTop <= scrollPosition) {
          setActiveId(ids[i])
          return
        }
      }

      setActiveId(ids[0])
    }

    document.addEventListener('scroll', handleScroll)

    return () => document.removeEventListener('scroll', handleScroll)
  }, [ids])

  return activeId
}

const TableOfContent: React.FC = () => {
  const headings = useHeadings()
  const activeId = useScrollSpy(headings.map(({ id }) => id))
  const [updateActiveHeading, setUpdateActiveHeading] = useState('')
  const isDown1200 = useMediaQuery('(max-width:1200px)')

  const scrollToHeading = (id: string) => {
    const element = document.getElementById(id)

    if (element) {
      const offset = element.offsetTop
      const navHeight = 70
      const midOfScreen = window.innerHeight / 2

      const targetScrollPosition = offset - midOfScreen + navHeight

      window.scrollTo({
        top: targetScrollPosition,
        behavior: 'smooth',
      })

      setTimeout(() => {
        setUpdateActiveHeading(id)
      }, 200)
    }
  }

  useEffect(() => {
    if (updateActiveHeading) {
      const element = document.getElementById(updateActiveHeading)

      if (element) {
        const offset = element.offsetTop
        const midOfScreen = window.innerHeight / 2

        window.scrollTo({
          top: offset - midOfScreen,
          behavior: 'smooth',
        })
      }
      setUpdateActiveHeading('')
    }
  }, [updateActiveHeading])
  return (
    <Box
      sx={{
        position: 'sticky',
        top: '5em',
        // width: `calc(${window.innerWidth}px - 44px)`,
        maxHeight: '100vh',
        overflowY: 'auto',
        paddingBottom: isDown1200 ? '32px' : '0px',
        minWidth: '300px',
        width: '100%',
        background: 'unset',
      }}
    >
      <Box
        className="toc"
        component={'nav'}
        sx={{
          marginRight: '20px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '& a:hover': {
            color: !isDown1200 ? '#ffffff !important' : null,
          },
          scrollbarWidth: 'none',
        }}
      >
        <ul
          style={{
            listStyle: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: isDown1200 ? 'row' : 'column',
            gap: isDown1200 ? '16px' : '0px',
            width: 'max-content',
          }}
        >
          {headings.map(heading => (
            <li
              key={heading.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '14px',
                paddingBottom: isDown1200 ? '0px' : '12px',
                textWrap: 'nowrap',
              }}
            >
              <img
                src={NavGreenArrow}
                style={{
                  display: isDown1200 ? 'none' : 'block',
                  opacity: activeId === heading.id ? 1 : 0,
                }}
                alt="arrow"
              />
              <a
                onClick={() => scrollToHeading(heading.id)}
                style={{
                  display: 'block',
                  color: activeId === heading.id ? '#FFFFFF' : '#767676',
                  textDecoration: 'none',
                  transition: 'all 0.3s ease-out',
                  fontWeight: 400,
                  fontFamily: 'Inter',
                  fontSize: '15px',
                }}
                href={`#${heading.id}`}
              >
                {heading.text}
              </a>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  )
}

const ProjectDetails = ({ projectData }: { projectData: any }) => {
  const isBelow1000 = useMediaQuery('(max-width:1000px)')
  const utilityLength = projectData.token?.utility?.data?.length / 2 || 0
  const isResearchPage =
    String(_.get(projectData, 'type')).toLowerCase() === 'research'

  const dynamicUtilityBoxes = () => {
    const utilityData = projectData.token?.utility?.data || []
    const rows = []
    const itemsPerRow = 2 // Two items per row
    const numberOfRows = Math.ceil(utilityData.length / itemsPerRow)

    for (let i = 0; i < numberOfRows; i++) {
      const rowStart = i * itemsPerRow
      const rowEnd = rowStart + itemsPerRow
      const rowItems = utilityData.slice(rowStart, rowEnd)

      rows.push(
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {rowItems.map((feature: any, index: any) => (
            <>
              {feature?.text[0] ? (
                <KeyFeatureBox
                  key={rowStart + index}
                  id={rowStart + index + 1}
                  headingText={
                    feature?.text[0]
                    // ? feature?.text[0]?.split('-')[0] : '-'
                  }
                  subTexts={
                    // feature?.text[0]
                    //   ? feature?.text[0].indexOf('-') !== -1
                    //     ? feature?.text[0].substring(
                    //         feature?.text[0].indexOf('-') + 1,
                    //       )
                    //     : ''
                    //   : '-'
                    feature?.text.length > 1 ? feature?.text.slice(1) : []
                  }
                />
              ) : null}
            </>
          ))}
        </Box>,
      )
    }

    return rows
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={3}
        sx={{
          position: 'sticky',
          top: '68px',
          // backgroundColor: '#000000',
          height: '55px',
        }}
      >
        <TableOfContent />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <article style={{ maxWidth: '100%' }}>
          <Heading as="h2">Key Metrics</Heading>
          <StyledBox>
            <InfoBox
              imageSrc={KeyMetrics1}
              headingText="Blockchain Network"
              headingVal={projectData.token.network}
              // tokenSymbol={projectData.token.symbol}
            />
            {!isResearchPage && (
              <InfoBox
                imageSrc={KeyMetrics2}
                headingText="Total Supply"
                headingVal={
                  projectData.token?.totalSupply?.toLocaleString('en') || 0
                }
                // headingVal={projectData.token.hardcap.toLocaleString('en')}
              />
            )}
            {isResearchPage && (
              <InfoBox
                imageSrc={KeyMetrics6}
                headingText="Initial Market Cap"
                headingVal={`$ ${(
                  projectData.token?.initialMarketCap || '0'
                ).toLocaleString('en')}`}
              />
            )}
          </StyledBox>
          <StyledBox
            sx={{
              display: isResearchPage ? 'none' : '',
            }}
          >
            <InfoBox
              imageSrc={KeyMetrics3}
              headingText="Hard Cap"
              // headingVal={
              //   '$' + projectData.token.fullyDilutedMarketCap.toLocaleString('en')
              // }
              headingVal={
                projectData.token.hardcap === -1
                  ? 'N/A'
                  : `$ ${(projectData.token?.hardcap || '0').toLocaleString(
                      'en',
                    )}`
              }
            />
            <InfoBox
              imageSrc={KeyMetrics4}
              headingText="Platform Raise"
              // headingVal={'$' + projectData.token.totalSupply.toLocaleString('en')}
              headingVal={
                projectData.token.platformRaise === -2
                  ? 'Launch Drop'
                  : `$ ${(
                      projectData.token?.platformRaise || '0'
                    ).toLocaleString('en')}`
              }
            />
          </StyledBox>
          <StyledBox
            sx={{
              display: isResearchPage ? 'none' : '',
            }}
          >
            <InfoBox
              imageSrc={KeyMetrics5}
              headingText="Project Valuation"
              headingVal={
                projectData.token.valuation === -1
                  ? 'N/A'
                  : `$ ${(projectData.token?.valuation || '0').toLocaleString(
                      'en',
                    )}`
              }
            />
            <InfoBox
              imageSrc={KeyMetrics6}
              headingText="Initial Market Cap"
              headingVal={`$ ${(
                projectData.token?.initialMarketCap || '0'
              ).toLocaleString('en')}`}
            />
          </StyledBox>

          <Divider
            sx={{
              backgroundColor: '#1D1F21',
              height: '1px',
              mt: '40px',
              mb: '48px',
            }}
          />

          <Heading as="h1">Overview</Heading>
          {projectData.overview.map((overview: any, index: number) => (
            <Typography
              key={index}
              sx={{
                color: '#7E8180',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                paddingBottom: '24px',
              }}
            >
              {overview}
            </Typography>
          ))}

          {(!isResearchPage || projectData?.keyFeatures?.length > 0) && (
            <>
              <Divider
                sx={{
                  backgroundColor: '#1D1F21',
                  height: '1px',
                  mt: '24px',
                  mb: '48px',
                }}
              />
              <Heading as="h1">Key Features</Heading>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '8px',
                }}
              >
                {projectData.keyFeatures.map((feature: any, index: number) => (
                  <KeyFeatureBox
                    key={index}
                    id={index + 1}
                    headingText={feature}
                  />
                ))}
              </Box>
            </>
          )}

          <Divider
            sx={{
              backgroundColor: '#1D1F21',
              height: '1px',
              mt: '48px',
              mb: '48px',
            }}
          />
          {
            (!isResearchPage || projectData?.marketFocusAndCompetition?.length > 0) && (
              <Heading as="h1">Market Traction</Heading>
            )
          }
          {projectData.marketFocusAndCompetition.map((line: string) => (
            <Typography
              sx={{
                color: '#B1B4B3',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                pb: '1.5rem',
              }}
            >
              {line}
            </Typography>
          ))}

          {!isResearchPage && (
            <>
              <Divider
                sx={{
                  backgroundColor: '#1D1F21',
                  height: '1px',
                  mt: '48px',
                  mb: '48px',
                }}
              />
              <Heading as="h1">Token Utility</Heading>
              <Typography
                sx={{
                  color: '#B1B4B3',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  paddingBottom: '24px',
                }}
              >
                {projectData.token?.utility?.heading}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  flexWrap: 'wrap',
                }}
              >
                {dynamicUtilityBoxes()}
              </Box>
            </>
          )}

          {!isResearchPage && (
            <>
              <Divider
                sx={{
                  backgroundColor: '#1D1F21',
                  height: '1px',
                  mt: '48px',
                  mb: '48px',
                }}
              />
              <Heading as="h1">Revenue</Heading>
              {projectData?.revenueStreams?.data?.map(
                (revenue: any, index: number) => (
                  <Typography
                    key={index}
                    sx={{
                      color: '#7E8180',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      paddingBottom: '24px',
                    }}
                  >
                    {revenue.text[0]}
                  </Typography>
                ),
              )}
            </>
          )}

          {!isResearchPage && (
            <>
              <Divider
                sx={{
                  backgroundColor: '#1D1F21',
                  height: '1px',
                  mt: '24px',
                  mb: '48px',
                }}
              />
              <Heading as="h1">Token Metrics</Heading>
            </>
          )}
        </article>
      </Grid>
    </Grid>
  )
}

export default ProjectDetails
