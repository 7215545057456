//@ts-nocheck
import { NavLink } from "react-router-dom";
import { sideMenu } from "./config";
// import logoPng from "../../assets/revamp/images/logo.png";
import logoPng from "../../assets/ape-terminal-white.svg";
import twitterIcon from "../../assets/revamp/icons/twitter.svg";
import mediumIcon from "../../assets/revamp/icons/medium.svg";
import discordIcon from "../../assets/revamp/icons/discord.svg";
// import geckoIcon from "../../assets/revamp/icons/gecko.svg";
// import blurIcon from "../../assets/revamp/icons/blur.svg";

const Sidebar = () => {
  return (
    <header className="header">
      <div className="header-wrapper">
        <a className="logo" href="/" title="AnyFi">
          <img src={logoPng} alt="AnyFi" />
        </a>
        <nav>
          <ul>
            {sideMenu.menuHeader.map((navItem, id) => (
              <li key={id}>
                <NavLink
                  to={navItem.link}
                  className={({ isActive }) => `${isActive && "active"}`}
                >
                  {navItem.icon}
                  <span>{navItem.label}</span>
                </NavLink>
              </li>
            ))}
          </ul>
          <ul>
            <b>Products</b>
            {sideMenu.ProductsMenu.map((navItem, id) => (
              <li key={id}>
                <NavLink
                  to={navItem.link}
                  className={({ isActive }) =>
                    isActive && navItem.link !== "#"
                      ? "active"
                      : navItem.link !== "#"
                      ? ""
                      : "cusor-default"
                  }
                >
                  {navItem.icon}
                  <span>{navItem.label}</span>
                  {navItem.textNavComing && <em>{navItem.textNavComing}</em>}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul>
            <b>Account and Tools</b>
            {sideMenu.AccountandTools.map((navItem, id) => (
              <li key={id}>
                <NavLink
                  to={navItem.link}
                  className={({ isActive }) =>
                    isActive && navItem.link !== "#"
                      ? "active"
                      : navItem.link !== "#"
                      ? ""
                      : "cusor-default"
                  }
                >
                  {navItem.icon}
                  <span>{navItem.label}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="social">
          <b>Community</b>
          <aside>
            <a href="#" title="Twitter">
              <img src={twitterIcon} alt="" />
            </a>
            <a href="#" title="Medium">
              <img src={mediumIcon} alt="" />
            </a>
            <a href="#" title="Discord">
              <img src={discordIcon} alt="" />
            </a>
            {/* <a href="#" title="Coin Gecko">
              <img src={geckoIcon} alt="" />
            </a>
            <a href="#" title="Blur">
              <img src={blurIcon} alt="" />
            </a> */}
          </aside>
        </div>
        {/* <div className="copyright">
          <p>Anyfi © 2024. All Rights Reserved.</p>
          <span>
            <a href="#">Terms &amp; Conditions</a>
            <a href="#">Privacy Policy</a>
          </span>
        </div> */}
      </div>
    </header>
  );
};

export default Sidebar;
