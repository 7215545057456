import {
  CloseOutlined,
  LogoutOutlined,
  ProductOutlined,
} from '@ant-design/icons'
import { Typography } from 'antd'
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useSearchParams } from 'react-router-dom'
import { formatAddress } from '../..//utils/function'
import chevronIcon from '../../assets/revamp/icons/chevron.svg'
import globalIcon from '../../assets/revamp/icons/global.svg'
import searchIcon from '../../assets/revamp/icons/search.svg'
import bscIcon from '../../assets/revamp/images/tokens/Bitcoin.png'
import ethereumIcon from '../../assets/revamp/images/tokens/Ethereum.png'
import gnosiscIcon from '../../assets/revamp/images/tokens/Gnosis.png'
import hecoIcon from '../../assets/revamp/images/tokens/Heco.png'
import polygonIcon from '../../assets/revamp/images/tokens/Polygon.png'
import pulseIcon from '../../assets/revamp/images/tokens/PulseChain.png'
import Icon from '../../components/common/Icon'
import StorageService from '../../utils/storage'

const { Paragraph, Text } = Typography

interface PageHeaderProps {
  onOpenNetwork: () => void
  // onBundled: (bundled: any) => void
}

interface SearchItem {
  address: string
}

const PageHeader: React.FC<PageHeaderProps> = props => {
  const { onOpenNetwork } = props

  const [searchParams, setSearchParams] = useSearchParams()
  let activeAddress = searchParams.get('active') || ''

  const [recentSearches, setRecentSearch] = useState<SearchItem[] | null>(null)
  const [address, setAddress] = useState<string>('')
  const [isOpenRecent, setOpenRecent] = useState<boolean>(false)
  const [isOpenBundle, setOpenBundle] = useState<boolean>(false)
  const [bundledItem, setBundledItem] = useState<SearchItem[] | null>(null)
  const [isClickRecentitem, setClickRecentItem] = useState<boolean>(false)
  const recentSearchRef = useRef<HTMLDivElement>(null)
  const bundleListRef = useRef<HTMLDivElement>(null)

  // const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   let value = event.target.value
  //   setAddress(value)
  // }

  // const handleKeyup = (event: KeyboardEvent<HTMLInputElement>) => {
  //   event.preventDefault()

  //   if (event.key === 'Enter') {
  //     if (address) {
  //       setSearchParams({ active: address })
  //       // Find and update history search
  //       let history = StorageService.get('history') as SearchItem[]
  //       if (!history) {
  //         StorageService.set('history', [{ address }])
  //       } else {
  //         let addresses = history.filter(i => i.address === address)
  //         if (!addresses.length) {
  //           history.push({ address })
  //           StorageService.set('history', history)
  //         }
  //       }

  //       setOpenRecent(true)
  //       setRecentSearch(history)
  //       setAddress('')
  //     }
  //   }
  // }

  // const handleClickInput = () => {
  //   if (recentSearches && recentSearches.length) {
  //     setOpenRecent(true)
  //   }
  // }

  // const handleClickRecentItem = (address: string) => {
  //   setSearchParams({ active: address })
  //   setOpenRecent(false)
  //   setOpenBundle(false)
  // }

  // const handleRemoveRecentSearch = (address: string) => {
  //   let history = StorageService.get('history') as SearchItem[]
  //   if (history) {
  //     let index = history.findIndex(i => i.address === address)
  //     if (index !== -1) {
  //       history.splice(index, 1)
  //       StorageService.set('history', history)
  //       setRecentSearch(history)
  //     }
  //   }
  // }

  // const isBundleAvailable = (address: string) => {
  //   if (
  //     !recentSearches ||
  //     !recentSearches.length ||
  //     recentSearches.length < 2 ||
  //     !address
  //   )
  //     return false

  //   let bundledWallets = StorageService.get('bundledWallets') as SearchItem[]
  //   if (!bundledWallets) return true

  //   let currentItem = bundledWallets.findIndex(i => i.address === address)
  //   if (currentItem === -1) {
  //     return true
  //   }

  //   return false
  // }

  // const addToBundle = (address: string) => {
  //   let bundledWallets = StorageService.get('bundledWallets') as SearchItem[]
  //   if (!bundledWallets || !bundledWallets.length) {
  //     setBundledItem(recentSearches)
  //     setOpenRecent(false)
  //     StorageService.set('bundledWallets', recentSearches)

  //     onBundled(recentSearches)

  //     return
  //   }

  //   let index = bundledWallets.findIndex(i => i.address === address)
  //   if (index === -1) {
  //     bundledWallets.push({ address })
  //     StorageService.set('bundledWallets', bundledWallets)
  //     setBundledItem(bundledWallets)

  //     onBundled(bundledWallets)
  //   }

  //   setOpenRecent(false)
  // }

  // const handleClickBundle = () => {
  //   onBundled(bundledItem)
  //   setOpenBundle(false)
  // }

  // const handleRemoveBundle = () => {
  //   // Remove state
  //   setBundledItem(null)
  //   setOpenBundle(false)

  //   // Remove storage
  //   StorageService.set('bundledWallets', null)

  //   // Navigate to default page
  //   searchParams.delete('active')
  //   setSearchParams(searchParams)
  // }

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (
        recentSearchRef.current &&
        !recentSearchRef.current.contains(event.target as Node) &&
        (event.target as HTMLElement).tagName !== 'INPUT'
      ) {
        setOpenRecent(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (
        bundleListRef.current &&
        !bundleListRef.current.contains(event.target as Node) &&
        (event.target as HTMLElement).tagName !== 'BUTTON'
      ) {
        setOpenBundle(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    let history = StorageService.get('history') as SearchItem[]
    setRecentSearch(history)

    let bundled = StorageService.get('bundledWallets') as SearchItem[]
    if (bundled) {
      setBundledItem(bundled)
    }
  }, [])

  return (
    <>
      <section className="page-title">
        <div className="page-title-wrapper">
          <h1 className="h2">Overview</h1>
          <div className="page-title--form">
            <div className="page-title--form--network" onClick={onOpenNetwork}>
              <Icon src={globalIcon} width={24} />
              <b>Networks</b>
              <Icon src={chevronIcon} width={16} />
            </div>
            {/* <div className="search-container">
              <input
                className={`${isOpenRecent ? 'active' : ''}`}
                type="text"
                placeholder="Track any wallet address or ENS ..."
                value={address}
                onChange={handleInputChange}
                onKeyUp={handleKeyup}
                onClick={handleClickInput}
              />
              <Icon src={searchIcon} alt="Search" />
              {isOpenRecent && (
                <div className="search-recent" ref={recentSearchRef}>
                  <p>Recent Searches:</p>
                  <div className="recent-box">
                    <ul className="recent-addresses">
                      {recentSearches &&
                        [...recentSearches].reverse().map(search => (
                          <li className="search-item" key={search.address}>
                            <div
                              className="search-item__container"
                              onClick={() => {
                                handleClickRecentItem(search.address)
                              }}
                            >
                              <div className="search-item__logo">
                                <Jazzicon
                                  diameter={30}
                                  seed={jsNumberForAddress(search.address)}
                                />
                              </div>
                              <div className="search-item__info">
                                <p className="search-address">
                                  {formatAddress(search.address)}
                                </p>
                                <div className="search-item__networks">
                                  <img src={bscIcon} alt="" />
                                  <img src={ethereumIcon} alt="" />
                                  <img src={gnosiscIcon} alt="" />
                                  <img src={hecoIcon} alt="" />
                                  <img src={polygonIcon} alt="" />
                                  <img src={pulseIcon} alt="" />

                                  <div className="network-count">17</div>
                                </div>
                              </div>
                            </div>
                            {isBundleAvailable(search.address) && (
                              <button
                                onClick={() => addToBundle(search.address)}
                              >
                                Add
                              </button>
                            )}
                            <CloseOutlined
                              onClick={() =>
                                handleRemoveRecentSearch(search.address)
                              }
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div> */}

            {/* {bundledItem && bundledItem.length && (
              <div className="bundle-wrapper">
                <button
                  className="button transparent"
                  onClick={() => setOpenBundle(true)}
                >
                  Bundled wallets
                </button>

                {isOpenBundle && (
                  <div className="bundle-list" ref={bundleListRef}>
                    <div className="recent-box">
                      <ul className="recent-addresses">
                        <li onClick={handleClickBundle}>
                          <ProductOutlined /> <span>Bundled wallets</span>
                        </li>
                        {bundledItem &&
                          [...bundledItem].reverse().map(search => (
                            <li
                              className={`search-item ${
                                activeAddress === search.address ? 'active' : ''
                              }`}
                              key={search.address}
                            >
                              <div
                                className="search-item__container"
                                onClick={() => {
                                  handleClickRecentItem(search.address)
                                }}
                              >
                                <div className="search-item__logo">
                                  <Jazzicon
                                    diameter={30}
                                    seed={jsNumberForAddress(search.address)}
                                  />
                                </div>
                                <div className="search-item__info">
                                  <p className="search-address">
                                    {formatAddress(search.address)}
                                  </p>
                                  <div className="search-item__networks">
                                    <img src={bscIcon} alt="" />
                                    <img src={ethereumIcon} alt="" />
                                    <img src={gnosiscIcon} alt="" />
                                    <img src={hecoIcon} alt="" />
                                    <img src={polygonIcon} alt="" />
                                    <img src={pulseIcon} alt="" />

                                    <div className="network-count">17</div>
                                  </div>
                                </div>
                              </div>

                              <Text copyable={{ text: search.address }} />
                            </li>
                          ))}

                        <li onClick={handleRemoveBundle}>
                          <LogoutOutlined />
                          <span>End Session</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )} */}
          </div>
        </div>
      </section>
    </>
  )
}

export default PageHeader
