//@ts-nocheck
import { useAccount } from "wagmi";
import { PhantomWalletName } from "@solana/wallet-adapter-wallets";
import { useWallet } from "@solana/wallet-adapter-react";
import { NETWORKS_NAME, networks } from "../../../utils/network";
import StorageService from "../../../utils/storage";
import CheckIcon from "../../../anyfi-src/assets/icons/check_circle.svg";

const ConnectNetworkBox = ({ selectedNetwork, handleNetworkClick }) => {
  const { connected: isConnectedSol } = useWallet();
  const { chainId } = useAccount();
  const walletName = StorageService.get("walletName");

  return (
    <>
      {Object.values(networks).map((network, index) => {
        const isSelectedState = selectedNetwork === network.networkName;
        const isSelected =
          chainId &&
          chainId.toString() === network.chainId &&
          !selectedNetwork &&
          !isConnectedSol;
        const isSelectedSol =
          network.networkName === NETWORKS_NAME.SOLANA &&
          isConnectedSol &&
          walletName === PhantomWalletName &&
          !selectedNetwork;

        return (
          <div
            key={index}
            className={`list-network ${
              isSelectedState || isSelected || isSelectedSol ? "selected" : ""
            }`}
            onClick={() => handleNetworkClick(network.networkName)}
          >
            <div className="walletBoxIconWrap">
              <img src={network.icon} alt={`${network.networkName} Icon`} />
              <p>{network.networkName}</p>
            </div>
            {isSelectedSol && <img src={CheckIcon} alt="CheckIcon" />}
            {isSelected && <img src={CheckIcon} alt="CheckIcon" />}
            {isSelectedState && <img src={CheckIcon} alt="CheckIcon" />}
          </div>
        );
      })}
    </>
  );
};
export default ConnectNetworkBox;
