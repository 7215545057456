import { useRef, useState } from "react";
import PauseIcon from "../../../assets/revamp//icons/icon-pause.svg";
import { PlayIcon } from "../../icons/PlayIcon";
import video from "../../../assets/revamp//images/intro-video.mp4";

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoPlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <section className="featured-video">
      <div className="featured-video-wrapper">
        <div className="featured-video--video">
          <video
            ref={videoRef}
            id="video"
            className="z-100 h-full w-[49.625rem] max-w-full object-cover"
            width={794}
            height={166}
            controls={false}
            loop
            playsInline
            onClick={handleVideoPlayPause}
            preload="auto"
            src={video}
          ></video>

          <span id="play-video" onClick={handleVideoPlayPause}>
            {isPlaying ? <img src={PauseIcon} alt="Pause" /> : <PlayIcon />}
          </span>
        </div>
      </div>
    </section>
  );
};

export default VideoPlayer;
