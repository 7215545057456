import { useState, useEffect } from 'react'
import { Modal, Switch } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import StorageService from '../../utils/storage'
import { PORTFOLIO_NETWORKS, STORAGE_NETWORK_KEY } from '../../utils/constants'
import { INetwork } from './types'

interface NetworksModalProps {
  open: boolean
  onSave: (selected: string[]) => void
  onClose: () => void
}

const NetworksModal: React.FC<NetworksModalProps> = props => {
  const { open, onSave, onClose } = props
  const [networksSelected, setNetworkSelected] = useState<number[]>(
    PORTFOLIO_NETWORKS.map(i => i.id),
  )
  const [filterdNetworks, setFilteredNetworks] =
    useState<INetwork[]>(PORTFOLIO_NETWORKS)

  const onChangeToggle = (checked: boolean) => {
    setNetworkSelected(checked ? filterdNetworks.map(i => i.id) : [])
  }

  const selectNetwork = (id: number) => {
    if (!networksSelected.length) {
      setNetworkSelected([id])
      return
    }

    let newSelected = [...networksSelected]
    let networkSelectedIndex = newSelected.findIndex(
      networkId => networkId === id,
    )
    if (networkSelectedIndex === -1) {
      newSelected.push(id)
    } else {
      newSelected.splice(networkSelectedIndex, 1)
    }

    setNetworkSelected(newSelected)
  }

  const handleSaveNetworks = () => {
    let selected = PORTFOLIO_NETWORKS.map(network => {
      if (networksSelected.includes(network.id)) {
        return network.code
      }

      return null
    }).filter(n => n !== null) as string[]

    onSave(selected)
    StorageService.set(STORAGE_NETWORK_KEY, selected)
  }

  const handleFilterNetworks = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setFilteredNetworks(PORTFOLIO_NETWORKS)
    }

    let filtered = PORTFOLIO_NETWORKS.filter(network =>
      network.name.toLowerCase().includes(event.target.value.toLowerCase()),
    )
    setFilteredNetworks(filtered)
  }

  const numberOfSelected = () => {
    let selected = filterdNetworks.filter(network =>
      networksSelected.includes(network.id),
    )
    return selected.length || 0
  }

  useEffect(() => {
    let storageNetworks = StorageService.get(STORAGE_NETWORK_KEY)
    if (storageNetworks) {
      let defaultNetworks = PORTFOLIO_NETWORKS.filter(n =>
        storageNetworks.includes(n.code),
      ).map(n => n.id)
      setNetworkSelected(defaultNetworks)
    } else {
      setNetworkSelected(PORTFOLIO_NETWORKS.map(n => n.id))
    }
  }, [])

  const resetModal = () => {}

  return (
    <Modal
      title={
        <p>
          Networks <span>{numberOfSelected()}</span>
          <span>/ {PORTFOLIO_NETWORKS.length}</span>
        </p>
      }
      className="networks-modal"
      centered
      open={open}
      onCancel={onClose}
      footer={null}
      afterClose={resetModal}
    >
      <div className="networks-modal__body">
        <div className="networks-modal__search">
          <input
            type="text"
            placeholder="Search for an available network"
            onChange={handleFilterNetworks}
          />
        </div>
        <div className="networks-modal__list">
          <div className="networks-modal__toggle">
            <div className="network-type">
              <span>
                <UpOutlined />
              </span>
              <span>EVMs</span>
              <span>{numberOfSelected()}</span>
              <span>&nbsp;/&nbsp;{filterdNetworks.length}</span>
            </div>
            <div>
              <Switch
                onChange={onChangeToggle}
                checked={numberOfSelected() === filterdNetworks.length}
              />
            </div>
          </div>
          <div className="networks--list">
            {filterdNetworks.map(network => (
              <div
                key={network.id}
                className={`network-item ${
                  networksSelected.includes(network.id) ? 'active' : ''
                }`}
                onClick={() => selectNetwork(network.id)}
              >
                <img src={network.icon} alt="" />
                <p>{network.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="networks-modal__footer">
        <button onClick={onClose}>Close</button>
        <button
          onClick={handleSaveNetworks}
          disabled={!networksSelected.length}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default NetworksModal
