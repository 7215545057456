import 'metamask-csp-firefox'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/configureStore'
import Web3Provider from './components/Web3Provider'
import { SocketProvider } from './components/SocketProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import MuiTheme from './theme'
import { PersistGate } from 'redux-persist/integration/react'
import { Toaster } from 'react-hot-toast'

import './styles/index.scss'
import './index.css'

// WalletConnect does not work with webpack 5 i.e. react@18+
// This is required to make it work
import { Buffer } from 'buffer'
import { ParticipationEntryPointProvider } from './Context'
import { setupServiceWorker } from './services/notification'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { GOOGLE_CLIENT_ID, APP_ENV } from '../src/constant'
;(window as unknown as any).Buffer = (window as unknown as any).Buffer || Buffer

// amplitude.init('73b7617d7e536d286e2c757693a70218')

// disable console.log since it is messed up
// @ts-ignore
window.consoleLog = console.log
console.log = () => {}

// Showing current env mode
console.info(`Running in ${APP_ENV} mode.`,GOOGLE_CLIENT_ID);

setupServiceWorker()

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

// show maintenance page
if (false) {
  root.render(
    <React.StrictMode>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          fontSize: '42px',
          fontWeight: 700,
          backgroundColor: '#000',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        We are currently under maintenance. Please check back later.
      </div>
    </React.StrictMode>,
  )
} else
  root.render(
    <React.StrictMode>
      {/* <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 2500,
          style: {
            background: '#000000',
            color: '#FFFFFF',
            border: '1px solid #696969',
          },
        }}
      /> */}
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <SocketProvider>
            <PersistGate persistor={persistor}>
              <MuiTheme>
                <ParticipationEntryPointProvider>
                  <Web3Provider />
                </ParticipationEntryPointProvider>
              </MuiTheme>
            </PersistGate>
          </SocketProvider>
        </Provider>
      </GoogleOAuthProvider>
    </React.StrictMode>,
  )
