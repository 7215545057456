import { Box, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material'

import GreenButton from '../GreenButton'
import Countdown from 'react-countdown'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParticipationEntryPoint } from '../../Context'
import { getReplacedCdnEndpoint } from '../../utils'

interface ISalesCard {
  heading: string
  titleImage: string
  totalRaise?: number
  startsIn?: number
  registrationStarted?: boolean
  active?: boolean
  registrationEndDate?: string
  routeName?: string
  started?: boolean
}

const SalesCard: React.FC<ISalesCard> = ({
  heading,
  titleImage,
  totalRaise,
  startsIn,
  routeName,
  registrationStarted,
  registrationEndDate,
  started,
}) => {
  const [timePassed, setTimePassed] = useState(false)

  const navigate = useNavigate()

  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown1152 = useMediaQuery('(max-width:1152px)')

  // run useEffect every second
  useEffect(() => {
    setProjectLinkSource('')
    if (!startsIn) return
    const interval = setInterval(() => {
      if (startsIn && startsIn <= new Date().getTime()) {
        setTimePassed(true)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [startsIn])
  const { setProjectLinkSource } = useParticipationEntryPoint()
  return (
    <Box
      sx={{
        backgroundColor: '#0F1113',
        border: '1px solid rgba(255, 255, 255, 0.09)',
        borderRadius: '12px',
        display: 'flex',
        flex: 1,
        mb: '8px',
        minWidth: isDown767 ? '248px' : '350px',
        cursor: 'pointer',
        width: '100%',
      }}
      onClick={() => {
        setProjectLinkSource('Closed')
        registrationStarted && navigate(`/project/${routeName}`)
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingX={'16px'}
        my={'16px'}
        width={'100%'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'row'}
        >
          <img
            src={getReplacedCdnEndpoint(titleImage)}
            alt="launch-logo"
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '12px',
              objectFit: 'contain',
              border: '0.5px solid #1D1F21',
            }}
          />
          <Box display={'flex'} flexDirection={'column'} ml={'12px'}>
            <Typography
              fontWeight={500}
              fontSize={17}
              lineHeight={'27.2px'}
              letterSpacing={'0px'}
              textAlign={'left'}
              fontFamily={'Hellix'}
              color="#FFFFFF"
            >
              {heading}
            </Typography>
            <Typography
              fontWeight={400}
              fontSize={15}
              lineHeight={'22.5px'}
              letterSpacing={'0px'}
              textAlign={'left'}
              fontFamily={'Inter'}
              color="#B1B4B3"
            >
              {totalRaise === -1 || !registrationStarted
                ? 'TBA'
                : totalRaise === -2
                ? 'Launch Drop'
                : `$${totalRaise?.toLocaleString('en')}`}
            </Typography>
          </Box>
        </Box>

        {registrationStarted && registrationEndDate ? (
          <Countdown
            date={registrationEndDate}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              let _timerString = ''

              if (days > 0) {
                _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
              } else if (hours > 0) {
                _timerString = `${hours}h ${minutes}m ${seconds}s`
              } else {
                _timerString = `${minutes}m ${seconds}s`
              }
              return (
                <Typography
                  fontFamily={'Inter'}
                  fontWeight={400}
                  fontSize={14}
                  lineHeight={'16.8px'}
                  color={'#B1B4B3'}
                  sx={{
                    alignSelf: 'center',
                    background: '#1d1f21',
                    height: '28px',
                    borderRadius: '60px',
                    padding: '5px 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {completed ? (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 7.5L7.5 10L11.5 6"
                          stroke="#63EA71"
                          strokeWidth="1.6"
                        />
                        <circle
                          cx="8"
                          cy="8"
                          r="7"
                          stroke="#63EA71"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Ended
                    </span>
                  ) : (
                    _timerString
                  )}
                </Typography>
              )
            }}
          />
        ) : timePassed && registrationStarted ? (
          <Typography
            fontFamily={'Inter'}
            fontWeight={500}
            fontSize={14}
            lineHeight={'24px'}
            color={'#ff7722'}
          >
            Started
          </Typography>
        ) : (
          <Typography
            fontFamily={'Inter'}
            fontWeight={400}
            fontSize={14}
            lineHeight={'24px'}
            color={'#B1B4B3'}
            sx={{
              alignSelf: 'center',
              background: '#1d1f21',
              height: '28px',
              borderRadius: '60px',
              padding: '5px 10px',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'pre',
            }}
          >
            {!started ? (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 6.86863L3.93137 2.8H12.0686L8 6.86863Z"
                    stroke="#FFB152"
                    strokeWidth="1.6"
                    strokeLinecap="square"
                  />
                  <path d="M8 8L5 5H11L8 8Z" fill="#FFB152" />
                  <path d="M8 8L14 14L2 14L8 8Z" fill="#FFB152" />
                </svg>
                Coming Soon
              </span>
            ) : (
              'TBA'
            )}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default SalesCard

export const ChipText = ({ text, color }: any) => {
  return (
    <Box
      sx={{
        backgroundColor: '#141414',
        borderRadius: '12px',
        padding: '4px 8px 4px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 1.5,
      }}
    >
      <Typography
        fontWeight={400}
        fontSize={12}
        lineHeight={'18px'}
        color={color}
        fontFamily={'Inter'}
      >
        {text}
      </Typography>
    </Box>
  )
}

const SkeletonLoader = ({
  firstLineWidth,
  secondLineWidth,
  thirdLineWidth,
}: any) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      height={'24px'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexWrap={'wrap'}
        gap={'12px'}
      >
        <Skeleton variant="circular" width={16} height={16} animation={false} />
        <Skeleton
          variant="rectangular"
          width={firstLineWidth}
          height={12}
          animation={false}
        />
        <Skeleton
          variant="rectangular"
          width={secondLineWidth}
          height={12}
          animation={false}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width={thirdLineWidth}
        height={12}
        animation={false}
      />
    </Grid>
  )
}
