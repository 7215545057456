import { Box, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NotFoundSvg, NotFoundSvgMobile } from '../../assets'
import OutlinedButton from '../../components/Buttons/OutlinedButton'

const NotFound = () => {
  const navigate = useNavigate()

  const isDown767 = useMediaQuery('(max-width:767px)')
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // height: '100vh',
      }}
    >
      <img
        src={!isDown767 ? NotFoundSvg : NotFoundSvgMobile}
        style={{
          height: 'auto',
        }}
        alt="not found"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          top: isDown767 ? '-25%' : '-100px',
        }}
      >
        <OutlinedButton
          onClick={() => navigate('/')}
          sx={{
            height: '48px',
            width: '165px',
            backgroundColor: 'transparent',
            fontFamily: 'Inter',
            border: '1px solid #313335',
            marginLeft: isDown767 ? '0%' : '48%',
            marginTop: '-5px',
            '&:hover': {
              border: '1px solid #313335',
              backgroundColor: 'transparent',
            },
          }}
        >
          Go back home
        </OutlinedButton>
      </Box>
    </Box>
  )
}

export default NotFound
