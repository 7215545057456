import Chart from 'react-apexcharts'
import { numberFormat } from '../../utils/function'
import { ITokenAssetInfo } from '../../services/modules/portfolio-manager'
import { useMemo } from 'react'

function createDarkerColor(sampleColor: string, darkenAmount: number): string {
  // Remove the "#" symbol if present
  const color = sampleColor.replace('#', '')

  // Parse the hexadecimal color value
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)

  // Calculate the darker color
  const darkerR = Math.max(r - darkenAmount, 0)
  const darkerG = Math.max(g - darkenAmount, 0)
  const darkerB = Math.max(b - darkenAmount, 0)

  // Convert the darker RGB values back to hexadecimal
  const darkerColor = `#${darkerR.toString(16).padStart(2, '0')}${darkerG
    .toString(16)
    .padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`

  return darkerColor
}

function randomChartColors(count: number): string[] {
  let colors: string[] = []
  let coefficient = count <= 1 ? 50 : 150 / count

  for (let i = 0; i < count; i++) {
    let color = createDarkerColor(
      '#63EA71',
      i === 0 ? 50 : 50 + i * Math.round(coefficient),
    )
    colors.push(color)
  }

  return colors
}

interface TokenAllowcationChartProps {
  data: ITokenAssetInfo[]
}

const TokenAllowcationChart: React.FC<TokenAllowcationChartProps> = props => {
  let { data } = props

  const series = useMemo(
    () => data?.map(i => parseFloat(i.usd_value || '0')),
    [data],
  )

  const options = useMemo(() => {
    const labels = data?.map(
      i => numberFormat(parseFloat(i.balance), 2, 2) + ' ' + i.token.denom,
    )
    const colors = randomChartColors(data.length)

    return {
      chart: {
        width: 380,
        type: 'donut',
      },
      colors,
      labels,
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Inter, sans-serif',
                color: 'rgba(241,237,242,0.6)',
                offsetY: 25,
                fontWeight: 300,
                formatter: (val: string) => {
                  return val
                },
              },
              value: {
                show: true,
                fontSize: '18px',
                fontFamily: 'Inter, sans-serif',
                color: '#F1EDF2',
                offsetY: -25,
                formatter: function (val: string) {
                  return `$${numberFormat(parseFloat(val), 2, 2)}`
                },
              },
              total: {
                show: false,
                label: 'Total',
                color: '#373d3f',
                formatter: function (w: any) {
                  let total = w.globals.seriesTotals.reduce(
                    (a: number, b: number) => {
                      return a + b
                    },
                    0,
                  )

                  return '$' + numberFormat(parseFloat(total), 2, 2)
                },
              },
            },
            size: '85%',
          },
        },
      },
      stroke: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    }
  }, [data])

  if (!data || data.length === 0) {
    return null
  }

  return (
    <Chart
      options={options as any}
      series={series}
      type="donut"
      width={'100%'}
    />
  )
}

export default TokenAllowcationChart
