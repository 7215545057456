import { api } from '../../api'

export interface INetworkBasicInfo {
  type: string
  value: string
  decimals: number | null
}

export interface IExtendedInfo {
  description: string | null
  categories: string[] | null
  url_learn_more: string | null
}

export interface IChainProperty {
  chain: string
  id: {
    type: string
    value: string
  }
  decimals: number
}

export interface ITokenCommonInfo {
  name: string | null
  denom: string | null
  id: string | null
  display_id: string | null
  image: string | null
  latest_price: string | null
  price_24h_change: string | null
  chain_properties: IChainProperty | null
}

export enum ETierRange {
  day = '1d',
  week = '7d',
  month = '30d',
  year = '365d',
}

export interface IWalletBasicInfo {
  address: string
  chain: string
}

export interface ITraitInfo {
  Website: string
}

export interface IMarketplaceInfo {
  indexer_id: string | null
  slug_id: string | null
  url: string | null
  marketplace_id: string | null
}

export interface IConnectionInfo {
  id: string
  address: string
  chain: string
  marketplaces: IMarketplaceInfo[]
  number_of_assets: number | null
  number_of_owners: number | null
  available_traits: string[]
  token: ITokenCommonInfo
  last_24h_change: string | null
  last_24h_change_percentage: string
  name: string | null
  avatar: string | null
  banner: string | null
  description: string | null
  volume: string | null
  volume_usd: string | null
  average_price: string | null
  floor_price: string | null
  floor_price_usd: string | null
  market_cap: string | null
  market_cap_usd: string | null
  stats: string | null
  low_volume: boolean
  unknown_volume: boolean
  is_fully_index: boolean
}

export interface ITokenAssetInfo {
  token: ITokenCommonInfo
  wallet: IWalletBasicInfo
  usd_value: string
  balance: string
}

export interface INftAssetInfo {
  name: string
  id: string
  token_id: string
  chain: string
  traits: ITraitInfo
  traits_hash: string | null
  creator_address: string | null
  owner_address: string
  token: ITokenCommonInfo
  wallet: IWalletBasicInfo
  collection: IConnectionInfo
  description: string | null
  avatar: string | null
  video_avatar: string | null
  price: string | null
  url: string | null
  urls: Record<string, any>
  rarity_score: string | null
  rank: string | null
}

// Response interfaces
export interface ITokenInfo extends ITokenCommonInfo {
  extended_information?: IExtendedInfo
  addresses?: Record<string, INetworkBasicInfo>
}

export interface ITokenTimeSeries {
  tier: string
  timeSeries: Record<string, number>
}

export interface IUserAssets {
  tokenAssets: ITokenAssetInfo[]
  nftAssets: INftAssetInfo[]
}

export interface IAssetsTimeSeries extends ITokenTimeSeries {}

// Request interfaces
interface IFetchTokenInfoRequest {
  tokenId: string
}

interface IFetchTokenTimeSeriesRequest extends IFetchTokenInfoRequest {
  tokenId: string
  tierName: string
}

interface IFetchUserAssetsRequest {
  wallet: string
  chains: string[]
}

interface IFetchUserAssetsTimeSeriesRequest extends IFetchUserAssetsRequest {
  tierName: string
}

export const projectApi = api.injectEndpoints({
  endpoints: build => ({
    // * For get requests
    fetchTokenInfo: build.query<ITokenInfo, IFetchTokenInfoRequest>({
      query: ({ tokenId }) => `portfolio/token-info/${tokenId}`,
    }),
    fetchTokenTimeSeries: build.query<
      ITokenTimeSeries,
      IFetchTokenTimeSeriesRequest
    >({
      query: ({ tierName, tokenId }) => {
        const params = new URLSearchParams()

        params.append('tierName', tierName)
        params.append('tokenId', tokenId)

        return `portfolio/token-time-series/${tokenId}?${params.toString()}`
      },
    }),
    fetchUserAssets: build.query<IUserAssets, IFetchUserAssetsRequest>({
      query: ({ wallet, chains }) => {
        const params = new URLSearchParams()
        if (wallet) {
          params.append('wallet', wallet)
        }

        if (chains) {
          chains.forEach(chain => params.append('chains', chain))
        }

        return `portfolio/user-assets?${params.toString()}`
      },
    }),
    fetchUserAssetsTimeSeries: build.query<
      IAssetsTimeSeries,
      IFetchUserAssetsTimeSeriesRequest
    >({
      query: ({ chains, wallet, tierName }) => {
        const params = new URLSearchParams()
        if (wallet) {
          params.append('wallet', wallet)
        }

        if (chains) {
          chains.forEach(chain => params.append('chains', chain))
        }

        if (tierName) {
          params.append('tierName', tierName)
        }

        return `portfolio/user-assets-time-series?${params.toString()}`
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchTokenInfoQuery,
  useFetchTokenTimeSeriesQuery,
  useFetchUserAssetsQuery,
  useFetchUserAssetsTimeSeriesQuery,
  useLazyFetchTokenInfoQuery,
  useLazyFetchTokenTimeSeriesQuery,
} = projectApi
