import {
  Typography,
  Snackbar,
  Alert,
  TextField,
  FormControl,
} from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import { useCallback, useEffect, useState } from 'react'
import {
  useFetchUserSubscribeQuery,
  useSubscribeForNewsLetterAuthMutation,
  useUnSubscribeForNewsLetterMutation,
} from '../../services/modules/users'
import { useAppSelector } from '../../hooks'
import GreenButton from '../../components/GreenButton'

const SubscribeSubscription: React.FC = () => {
  const [subscribeForNewsLetterAuth] = useSubscribeForNewsLetterAuthMutation()
  const [unSubscribeForNewsLetter] = useUnSubscribeForNewsLetterMutation()

  const isDown767 = useMediaQuery('(max-width:767px)')

  const [message, setMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const user = useAppSelector(state => state.user)

  const { data, refetch } = useFetchUserSubscribeQuery(undefined, {
    skip: !user.googleToken && !user.mainAppToken,
  })

  const [valueEmail, setValueEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isSubscribed, setIsSubscribed] = useState(data?.isSubscribed || false)

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value
    setValueEmail(email)
    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address.')
    } else {
      setError(null)
    }
  }

  useEffect(() => {
    if (data?.isSubscribed !== undefined) {
      setIsSubscribed(data.isSubscribed)
    }
    if (data?.isSubscribed && data.receivedEmail) {
      setIsSubscribed(true)
      setValueEmail(data.receivedEmail)
    }
  }, [data])

  useEffect(() => {
    if (user.email || user.emailRegisterUserAccount) {
      setValueEmail(user.email || user.emailRegisterUserAccount || '')
    }
  }, [user.email, user.emailRegisterUserAccount])

  const handleUnSubscribe = useCallback(async () => {
    try {
      setIsSubscribed(false)
      if (user.accessTokenGoogle || user.mainAppToken) {
        const result = await unSubscribeForNewsLetter().unwrap()
        if (result) {
          setMessage(`${result.message.split('!')[0]} ${valueEmail} !`)
          setOpenSnackbar(true)
          refetch()
        }
      }
    } catch (error) {
      refetch()
      setIsSubscribed(false)
      console.log(error, 'error')
    }
  }, [
    refetch,
    unSubscribeForNewsLetter,
    user.accessTokenGoogle,
    user.mainAppToken,
    valueEmail,
  ])

  const handleSubscribe = useCallback(async () => {
    if (!validateEmail(valueEmail)) {
      setError('Please enter a valid email address.')
      return
    }

    try {
      setIsSubscribed(true)
      const result = await subscribeForNewsLetterAuth({
        email: valueEmail,
      }).unwrap()
      if (result) {
        const exclamationCount = result.message.split('!').length - 1
        const parts = result.message.split('!')
        let message = `${parts[0]}!`

        if (exclamationCount > 1) {
          message += `${parts[1]} ${valueEmail} !`
        } else {
          message += `${parts[1]} ${valueEmail} !`
        }

        setMessage(message)
        setOpenSnackbar(true)
        refetch()
      }
    } catch (error: any) {
      const { message } = error.data
      setError(message)
      refetch()
      setIsSubscribed(false)
      console.log(error, 'error')
    }
  }, [refetch, subscribeForNewsLetterAuth, valueEmail])

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Box width={'100%'}>
      <Box
        sx={{
          paddingBottom: '153px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            gap: '16px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontFamily: 'Hellix',
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '32px',
              textAlign: 'left',
            }}
          >
            Subscription
          </Typography>
          <Box
            sx={{
              border: '1px solid #00000033',
              borderRadius: '16px',
              display: 'flex',
              background: '#0f1113',
              justifyContent: 'space-between',
              flexDirection: isDown767 ? 'column' : 'row',
              alignItems: isDown767 ? 'start' : 'center',
              padding: '12px 16px',
              width: '100%',
              gap: isDown767 ? '16px' : '',
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: '#fff',
                  fontFamily: 'Hellix',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                Subscribe to our newsletter
              </Typography>
              <Typography
                sx={{
                  color: '#7E8180',
                  fontFamily: 'Inter',
                  fontSize: '13px',
                  fontWeight: '400',
                  lineHeight: '15.6px',
                  paddingTop: '10px',
                }}
              >
                Subscribe to receive emails on our latest project, don’t miss
                your investment opportunity
              </Typography>
            </Box>
            <FormControl fullWidth={isDown767 ? true : false}>
              <TextField
                value={valueEmail}
                placeholder="Enter your email"
                error={!!error}
                helperText={error}
                onChange={handleEmailChange}
                autoComplete="off"
                variant="outlined"
                InputProps={{
                  readOnly: isSubscribed,
                }}
                sx={{
                  
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px !important',
                    border: '1px solid #00000033',
                    fontFamily: 'Inter',
                    fontSize: '17px',
                    fontWeight: '500',
                    lineHeight: '32px',
                    color: '#fff !important',
                    outline: 'unset !important',
                  },
                  '& .MuiOutlinedInput-input': {
                    color: '#fff !important',
                    outline: 'unset !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    outline: 'unset !important',
                  },
                }}
              />
            </FormControl>
            <GreenButton
              fs={15}
              lh="22.5px"
              fm="Inter"
              fw={400}
              br="24px"
              text={isSubscribed ? 'Subscribed' : 'Subscribe'}
              onClick={isSubscribed ? handleUnSubscribe : handleSubscribe}
              outlined={true}
              w={'fit-content'}
              sx={{
                marginLeft:'16px',
                minWidth: '71px',
                border: '1px solid #08493C',
                padding: '16px',
                backgroundColor: 'transparent',
                '&:hover': {
                  border: '1px solid #27B079',
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default SubscribeSubscription
