//@ts-nocheck
import { HomeIcon } from "../icons/HomeIcon"
import { UserPortalIcon } from "../icons/UserPortalIcon";
import { PortfolioIcon } from "../icons/PortfolioIcon";
import { LaunchpadIcon } from "../icons/LaunchpadIcon";
import { ConnectfiIcon } from "../icons/ConnectfiIcon";
import { AccountIcon } from "../icons/AccountIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { GuideIcon } from "../icons/GuideIcon";
// import { YieldBoxIcon } from "../icons/YieldBoxIcon";
// import { SpendIcon } from "../icons/SpendIcon";
import { TradingIcon } from "../icons/TradingIcon";
import { WhitepaperIcon } from "../icons/WhitepaperIcon";
import { PATHS, labelNavbar } from "../../constants/config";

//TODO: MUI icons must change to design icon
import InfoIcon from '@mui/icons-material/Info';
import CurtainsIcon from '@mui/icons-material/Curtains';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';

export const sideMenu = {
  menuHeader: [
    {
      label: labelNavbar.home,
      icon: <HomeIcon />,
      link: PATHS.home,
    },
    {
      label: labelNavbar.userPortal,
      icon: <UserPortalIcon />,
      link: PATHS.userPortal,
    },
    {
      label: labelNavbar.portfolio,
      icon: <PortfolioIcon />,
      link: PATHS.portfolio,
    },
  ],
  ProductsMenu: [
    {
      label: labelNavbar.investmentPortal,
      icon: <LaunchpadIcon />,
      link: PATHS.investmentPortal,
    },
    {
      label: labelNavbar.launchPool,
      icon: <SwitchAccessShortcutIcon className="overview-sidebar-icon" />,
      link: PATHS.launchPool,
      textNavComing: 'Coming Soon',
    },
    // {
    //   label: labelNavbar.connectFi,
    //   icon: <ConnectfiIcon />,
    //   link: PATHS.connectFi,
    //   textNavComing: 'Coming Soon',
    // },
    // {
    //   label: labelNavbar.yieldBox,
    //   icon: <YieldBoxIcon />,
    //   link: PATHS.yieldbox,
    // },
    // {
    //   label: labelNavbar.Spend,
    //   icon: <SpendIcon />,
    //   link: PATHS.spend,
    //   textNavComing: 'Coming Soon',
    // },
    // {
    //   label: labelNavbar.Trading,
    //   icon: <TradingIcon />,
    //   link: '#',
    //   textNavComing: 'Coming Soon',
    // },
  ],
  AccountandTools: [
    {
      label: labelNavbar.account,
      icon: <AccountIcon />,
      link: PATHS.account,
    },
    // {
    //   label: labelNavbar.settings,
    //   icon: <SettingsIcon />,
    //   link: '#',
    // },
    // {
    //   label: labelNavbar.about,
    //   icon: <InfoIcon className="overview-sidebar-icon" />,
    //   link: '#',
    // },
    // {
    //   label: labelNavbar.transparency,
    //   icon: <CurtainsIcon className="overview-sidebar-icon" />,
    //   link: '#',
    // },
    // {
    //   label: labelNavbar.apeActivator,
    //   icon: <SpellcheckIcon className="overview-sidebar-icon" />,
    //   link: '#',
    // },
    {
      label: labelNavbar.news,
      icon: <NewspaperIcon className="overview-sidebar-icon" />,
      link: PATHS.news,
    },
    {
      label: labelNavbar.guide,
      icon: <GuideIcon />,
      link: PATHS.guide,
    },
    // {
    //   label: labelNavbar.whitepaper,
    //   icon: <WhitepaperIcon />,
    //   link: '#',
    // },
  ],
};
