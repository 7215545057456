export const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
    >
      <path d="M53.5 32L22 50.1865L22 13.8135L53.5 32Z" fill="#F1EDF2" />
    </svg>
  );
};
