import { Box } from '@mui/material'
import { useAppSelector } from '../../hooks'
import AccountLogin from './AccountLogin'
import { useAccount } from 'wagmi'
import AccountInfo from './AccountInfo'

const Account = () => {
  const user = useAppSelector(state => state.user)
  const { address } = useAccount()
  const isLogIned = address || user.email || user.emailRegisterUserAccount
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {!isLogIned ? <AccountLogin /> : <AccountInfo />}
    </Box>
  )
}
export default Account
