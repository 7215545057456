export const PortfolioIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.80514 13.2666C3.51283 13.2666 3.26112 13.1611 3.05 12.95C2.83889 12.7389 2.73334 12.4871 2.73334 12.1948V3.80514C2.73334 3.50172 2.83889 3.24723 3.05 3.04167C3.26112 2.83612 3.51283 2.73334 3.80514 2.73334H12.1948C12.4983 2.73334 12.7527 2.83612 12.9583 3.04167C13.1639 3.24723 13.2666 3.50172 13.2666 3.80514V12.1948C13.2666 12.4871 13.1639 12.7389 12.9583 12.95C12.7527 13.1611 12.4983 13.2666 12.1948 13.2666H3.80514ZM12.4 4.66539L8.08974 9.08334L6.07692 7.08717L3.59999 9.5641V10.7731L6.07692 8.29612L8.08075 10.3L12.4 5.88202V4.66539Z"
        fill="#F1EDF2"
        fillOpacity="0.6"
      />
    </svg>
  );
};
