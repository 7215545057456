import { useEffect, useState } from "react";

const WIDTH_SM = 640;
const WIDTH_MD = 768;
const WIDTH_LG = 1024;
const WIDTH_XL = 1280;
const WIDTH_2XL = 1400;

export const useScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const responsive = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("load", responsive);
    window.addEventListener("resize", responsive);
    window.addEventListener("orientationchange", responsive);

    // initial run
    responsive();

    // remove listener
    return () => {
      window.removeEventListener("load", responsive);
      window.removeEventListener("resize", responsive);
      window.removeEventListener("orientationchange", responsive);
    };
  }, []);

  return {
    width,
    height,
    sx: width < WIDTH_SM,
    sm: width >= WIDTH_SM,
    md: width >= WIDTH_MD,
    lg: width >= WIDTH_LG,
    xl: width >= WIDTH_XL,
    "2xl": width >= WIDTH_2XL,
    breakpoint:
      width >= WIDTH_2XL
        ? "2xl"
        : width >= WIDTH_XL
        ? "xl"
        : width >= WIDTH_LG
        ? "lg"
        : width >= WIDTH_MD
        ? "md"
        : width >= WIDTH_SM
        ? "sm"
        : "xs",
  };
};
