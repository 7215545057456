import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'

type TimeBoxProps = {
  type: string
  value: number
  color?: string
}

const TimeBox: React.FC<TimeBoxProps> = ({ type, value, color }) => {
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown360 = useMediaQuery('(max-width:360px)')
  let textColor = ''

  switch (type) {
    case 'Days':
      // textColor = '#F4FDAB'
      textColor = '#FFFFFF'
      break
    case 'Hours':
      // textColor = '#FFB152'
      textColor = '#FFFFFF'
      break
    case 'Minutes':
      // textColor = '#FF7722'
      textColor = '#FFFFFF'
      break
    default:
      // textColor = '#63EA71'
      textColor = '#FFFFFF'
      break
  }

  return (
    <Box
      sx={{
        border: `1px solid rgba(255, 255, 255, 0.16)`,
        justifyContent: 'center',
        borderRadius: '12px',
        minWidth: '60px',
        minHeight: '74px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxWidth: isDown767 ? '100%' : '70px',
      }}
    >
      <Typography
        sx={{
          marginBottom: '2px',
          fontSize: '26px',
          fontWeight: 600,
          fontFamily: 'Hellix',
          lineHeight: '32.2px',
        }}
        color={textColor}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          fontFamily: 'Inter',
          color: 'rgba(118, 118, 118, 1)',
          lineHeight: '15px',
        }}
      >
        {type}
      </Typography>
    </Box>
  )
}

export default TimeBox
