import { CONTRACT_ADDRESS } from '../constant'
import { getChainIdFromName } from '../utils'
import { useAccount, useReadContract } from 'wagmi'
import ClaimNewAbi from '../contracts/claim_new.json'
import { IResponseUserPortalItem } from '../services/modules/participationsV2'

interface UseClaimDataProps {
  sale: IResponseUserPortalItem
}

export const useClaimData = ({ sale }: UseClaimDataProps) => {
  const { address } = useAccount()

  const netWork = sale?.idoClaimNetwork || sale?.tokenNetwork
  const claimChainId: number | undefined = getChainIdFromName(netWork)
  const claimProjectId = Number(sale?.projectVestingId) || 0
  const claimContract =
    sale?.idoClaimContract ||
    CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId!]

  const { data: claimData } = useReadContract({
    address: claimContract,
    abi: ClaimNewAbi,
    functionName: 'getAccountStatsAt',
    args: [claimProjectId, address],
  }) as any

  const { data: dataProject } = useReadContract({
    address: claimContract,
    abi: ClaimNewAbi,
    functionName: 's_project',
    args: [claimProjectId],
  }) as any

  return { claimData, dataProject }
}
