export const LaunchpadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.21675 7.62049L4.85777 4.96281C4.97828 4.8423 5.11395 4.75469 5.2648 4.69999C5.41565 4.64529 5.57484 4.63247 5.74237 4.66153L6.96417 4.81794C6.51545 5.36666 6.09302 5.93909 5.69687 6.53524C5.30071 7.13139 4.96588 7.76066 4.69238 8.42304L2.21675 7.62049ZM5.26672 8.51919C5.56159 7.78331 5.93382 7.09549 6.38338 6.45574C6.83296 5.816 7.33766 5.23288 7.89748 4.70639C8.65816 3.97905 9.48294 3.39081 10.3718 2.94166C11.2607 2.49252 12.2825 2.33034 13.4372 2.45513C13.562 3.59872 13.3959 4.61217 12.9391 5.49549C12.4823 6.37882 11.9013 7.20082 11.1962 7.96149C10.6654 8.53671 10.0795 9.05145 9.4385 9.50573C8.79748 9.95999 8.10902 10.3346 7.37312 10.6294L5.26672 8.51919ZM8.98978 6.91279C9.19319 7.11621 9.43806 7.21791 9.72438 7.21791C10.0107 7.21791 10.2556 7.11621 10.459 6.91279C10.6624 6.70937 10.7641 6.46557 10.7641 6.18139C10.7641 5.89721 10.6624 5.6534 10.459 5.44998C10.2556 5.24657 10.0107 5.14486 9.72438 5.14486C9.43806 5.14486 9.19319 5.24657 8.98978 5.44998C8.78636 5.6534 8.68465 5.89721 8.68465 6.18139C8.68465 6.46557 8.78636 6.70937 8.98978 6.91279ZM8.26925 13.6922L7.47312 11.2166C8.13551 10.932 8.76648 10.5933 9.36605 10.2006C9.96562 9.80784 10.5342 9.38712 11.0718 8.93841L11.4346 9.74354C11.4859 9.98883 11.4575 10.2219 11.3494 10.4429C11.2413 10.6638 11.0992 10.8623 10.9231 11.0384L8.26925 13.6922ZM3.2988 10.5153C3.59024 10.2239 3.94558 10.0775 4.3648 10.0763C4.78402 10.075 5.13936 10.2201 5.43082 10.5115C5.72226 10.803 5.85687 11.15 5.83465 11.5525C5.81243 11.9551 5.65559 12.3076 5.36415 12.6102C4.90091 13.1068 4.35945 13.4149 3.7398 13.5345C3.12014 13.6542 2.58212 13.735 2.12573 13.7768C2.16761 13.3204 2.255 12.7814 2.3879 12.1596C2.52081 11.5378 2.82444 10.9897 3.2988 10.5153Z"
        fill="#F1EDF2"
        fillOpacity="0.6"
      />
    </svg>
  );
};
