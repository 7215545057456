import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { formatBigNumber, getReplacedCdnEndpoint } from '../../../utils'
import { formatUnixToDate } from '../../../constants/formatDate'
import {
  IResponseUserPortalItem,
  IResponseUserPortalMeta,
} from 'services/modules/participationsV2'
import OutlinedButton from '../../../components/Buttons/OutlinedButton'
import { getNetworkIconByChain } from './IconByNetworkUserPortal'
import BigNumber from 'bignumber.js'
import { useClaimData } from '../../../hooks/useClaimData'

export const StyledHeadTableCell = styled(TableCell)({
  backgroundColor: '#000000',
  borderBottom: '1px solid #0F1113',
  color: '#767676',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  height: '48px',
  flexShrink: 1,
  padding: '0px 20px',
  whiteSpace: 'nowrap',
})

export const FixedHeadTableCell = styled(StyledHeadTableCell)({
  position: 'sticky',
  left: 0,
  zIndex: 99,
  boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.5)',
  borderRight: '1px solid #1D1F21',
})

export const StyledBodyTableCell = styled(TableCell)({
  height: '72px',
  borderBottom: '1px solid #1D1F21',
  color: '#B1B4B3',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 20px',
  maxHeight: '56px',
  lineHeight: '15px',
})

export const FixedBodyTableCell = styled(StyledBodyTableCell)({
  position: 'sticky',
  left: 0,
  zIndex: 1,
  backgroundColor: '#0F1113',
  textAlign: 'center',
  boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.5)',
  borderRight: '1px solid #1D1F21',
})

export const getStatusStyle = (status: string) => {
  switch (status) {
    case 'won':
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#63EA71',
      }
    case 'invested':
      return {
        backgroundColor: 'rgba(255, 119, 34, 0.1)',
        color: '#FF7722',
      }
    case 'registered':
      return {
        backgroundColor: 'rgba(244, 253, 171, 0.1)',
        color: '#F4FDAB',
      }
    case 'ended':
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
    default:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
  }
}

const MySalesRow = ({
  sale,
  index,
}: {
  sale: IResponseUserPortalItem
  index: number
}) => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  const navigate = useNavigate()
  const netWork = sale?.idoClaimNetwork || sale?.tokenNetwork
  const { claimData, dataProject } = useClaimData({ sale })

  const claimedAmount = formatBigNumber(claimData?.[2], dataProject?.[13])

  const valueLocked = sale.valueLocked
    ? BigNumber(sale.valueLocked).minus(claimedAmount)
    : '0'

  return (
    <TableRow
      key={index}
      onClick={() => navigate(`/project/${sale.projectName}`)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#000000',
        },
      }}
    >
      {isDown800 ? (
        <FixedBodyTableCell>
          <img
            src={getReplacedCdnEndpoint(sale.projectLogoUrl)}
            alt={sale.projectName}
            style={{ width: 40, height: 40, borderRadius: 10 }}
          />
        </FixedBodyTableCell>
      ) : (
        <StyledBodyTableCell>
          <Box display="flex" alignItems="center">
            <img
              src={getReplacedCdnEndpoint(sale.projectLogoUrl)}
              alt={sale.projectName}
              style={{ width: 40, height: 40, borderRadius: 10 }}
            />
            <Box ml={'12px'}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                }}
              >
                {sale.projectName}
              </Typography>
              <Typography
                sx={{
                  color: '#7E8180',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {sale.tokenSymbol}
              </Typography>
            </Box>
          </Box>
        </StyledBodyTableCell>
      )}
      <StyledBodyTableCell>
        {Number(sale.nextUnlock) ? formatUnixToDate(sale.nextUnlock) : 'TBD'}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        <img src={getNetworkIconByChain(netWork)} alt={netWork} />
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {sale.idoTotalParticipation
          ? Number(sale.idoTotalParticipation).toLocaleString('en')
          : '0'}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        $
        {sale.idoTotalAssetsConnected
          ? Number(sale.idoTotalAssetsConnected).toLocaleString('en')
          : '0'}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {Number(valueLocked).toLocaleString('en')}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '88px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',

            ...getStatusStyle(
              sale.invested ? 'invested' : sale.isWinner ? 'won' : 'registered',
            ),
          }}
        >
          {sale.invested ? 'Invested' : sale.isWinner ? 'Won' : 'Registered'}
        </Box>
      </StyledBodyTableCell>
    </TableRow>
  )
}

const MySalesTable = ({
  mySales,
  handleShowMore,
  meta,
  isShowButton,
}: {
  isShowButton: boolean
  meta: IResponseUserPortalMeta | undefined
  mySales: IResponseUserPortalItem[]
  handleShowMore: React.MouseEventHandler<HTMLButtonElement>
}) => {
  const isDown800 = useMediaQuery('(max-width:800px)')

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minHeight: mySales.length <= 5 ? '100%' : '408px',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  textWrap: 'nowrap',
                }}
              >
                {isDown800 ? (
                  <FixedHeadTableCell>Projects</FixedHeadTableCell>
                ) : (
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                )}
                <StyledHeadTableCell>Next Unlock</StyledHeadTableCell>
                <StyledHeadTableCell>Chain</StyledHeadTableCell>
                <StyledHeadTableCell>Participants</StyledHeadTableCell>
                <StyledHeadTableCell>Connected</StyledHeadTableCell>
                <StyledHeadTableCell>Value Locked</StyledHeadTableCell>
                <StyledHeadTableCell>Investment Status</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {mySales.map((item: IResponseUserPortalItem, index: number) => {
                return <MySalesRow sale={item} index={index} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {!isShowButton && Number(meta?.total_records) > 10 && (
        <Box mt={2} textAlign="center">
          <OutlinedButton
            onClick={handleShowMore}
            sx={{ height: '48px', width: '100%' }}
          >
            {Number(meta?.total_records) < mySales.length
              ? 'Show More'
              : 'Show Less'}
          </OutlinedButton>
        </Box>
      )}
    </Box>
  )
}

export default MySalesTable
