export const GuideIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.80486 13.2667C3.51255 13.2667 3.26084 13.1611 3.04973 12.95C2.83862 12.7389 2.73306 12.4872 2.73306 12.1949V3.8052C2.73306 3.50178 2.83862 3.24729 3.04973 3.04173C3.26084 2.83618 3.51255 2.7334 3.80486 2.7334H12.1946C12.498 2.7334 12.7525 2.83618 12.958 3.04173C13.1636 3.24729 13.2664 3.50178 13.2664 3.8052V12.1949C13.2664 12.4872 13.1636 12.7389 12.958 12.95C12.7525 13.1611 12.498 13.2667 12.1946 13.2667H3.80486ZM8.1664 8.50515L9.59971 7.80773L11.033 8.50515V3.60005H8.1664V8.50515Z"
        fill="#F1EDF2"
        fillOpacity="0.6"
      />
    </svg>
  );
};
