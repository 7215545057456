import moment from "moment";
export const DATE_FORMAT_DOMY = "Do MMM, YYYY";
export const DATE_FORMAT_MDY = "MMM D, YYYY";
export const DATETIME_FORMAT_UTC = "DD/MM/YY HH:mm [UTC]";
export const FORMAT_FULL_DATE = "DD MMMM YYYY";

export const formatDuration = (duration: moment.Duration | number) => {
  const momentDate = moment.duration(duration);
  const months = momentDate.months();
  const days = momentDate.days();
  const hours = momentDate.hours();
  const minutes = momentDate.minutes();
  const seconds = momentDate.seconds();

  if (Number(duration) <= 0) return "Ended";

  if (months > 0) {
    return `${Math.abs(months)}m ${Math.abs(days)}d ${Math.abs(
      hours
    )}h ${Math.abs(minutes)}m `;
  }
  if (days <= 0 && hours <= 24 && hours >= 1) {
    return `${Math.abs(hours)}h ${Math.abs(minutes)}m `;
  }
  if (days > 0) {
    return `${Math.abs(days)}d ${Math.abs(hours)}h ${Math.abs(minutes)}m `;
  }
  if (hours < 1) {
    return `${Math.abs(minutes)}m ${Math.abs(seconds)}s `;
  }
};

export function formatDate(isoString: string, format = DATE_FORMAT_MDY) {
  const date = moment(isoString);

  return date.format(format);
}
export function formatUnixToDate(timestamp: number, format = DATE_FORMAT_DOMY) {
  return moment.unix(Math.floor(timestamp / 1000)).format(format);
}

export function formatTimestampToUTC(
  timestamp: number,
  format = DATETIME_FORMAT_UTC
) {
  const date = moment.utc(timestamp * 1000);
  return date.format(format);
}
