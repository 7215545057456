import {
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { SearchIcon } from '../../../assets'
import MySalesTable from './MySalesTable'
import NoDataTable from './NoDataTable'
import NoDataBox from './NoDataBox'
import { Box, useMediaQuery } from '@mui/material'
import SkeletonForMySalesTable from './SkeletonForMySalesTable'
import ClaimTable from './ClaimTable'
import SkeletonForClaimTable from './SkeletonForClaimTable'
import MyReferralsTable from './MyReferralsTable'
import SkeletonForMyReferralsTable from './SkeletonForMyReferralsTable'
import FilterStatusMySales, { IFilterStatus } from './FilterStatusMySales'
import { useFetchUserReferralsWithSearchQuery } from '../../../services/modules/reffer'
import { useAppSelector } from '../../../hooks'
import { useAccount } from 'wagmi'
import { sortItemsBySmallerTimestamp } from '../../../utils'
import { IdoStatus } from '../../../services/modules/project'
import {
  IResponseUserPortalItem,
  useFetchUserParticipationsV2Query,
} from '../../../services/modules/participationsV2'
import { useLocation } from 'react-router-dom'

interface LocationState {
  activeTab?: ETabsName
}

export enum ETabsName {
  Sale = 'Sale',
  Claim = 'Claim',
  Referral = 'Referral',
}
const mySalesHeaders = [
  'Projects',
  'Next Unlock',
  'Chain',
  'Participants',
  'Connected',
  'Value Locked',
  'Status',
]
const referralsHeaders = [
  'Projects',
  'People used my link',
  'Multiplier',
  'Status',
  'My Referral links',
]
const claimHeaders = [
  'Projects',
  'Next Unlock',
  'Chain',
  'Status',
  'Allocation',
  'Distribution',
  'Claimable',
  'Claimed',
  'Claim',
]

const statusOrder: IdoStatus[] = [
  IdoStatus.PARTICIPATION,
  IdoStatus.INVESTMENT,
  IdoStatus.TGE,
  IdoStatus.COMPLETED,
]

const UserTransactions = () => {
  const limit = 10
  const location = useLocation()
  const { state } = location as { state: LocationState | null }
  const isDown1536 = useMediaQuery('(max-width:1536px)')
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const [page, setPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState<IFilterStatus>(
    IFilterStatus.ALL,
  )
  const [data, setData] = useState<IResponseUserPortalItem[]>([])
  const [searchName, setSearchName] = useState('')
  const [debouncedSearchName, setDebouncedSearchName] = useState('')
  const [activeTab, setActiveTab] = useState(ETabsName.Sale)
  const mainAppToken = useAppSelector(state => state.user.mainAppToken)
  const { address } = useAccount()
  const [isShowButton, setIsShowButton] = useState(false)

  const {
    data: userReferralData,
    isLoading: userReferralDataLoading,
    refetch: fetchUserReferrals,
  } = useFetchUserReferralsWithSearchQuery(
    { name: searchName || undefined },
    {
      skip: !address && !mainAppToken,
      refetchOnFocus: true,
    },
  )

  const {
    data: fetchedData,
    isLoading,
    refetch: fetchUserAllParticipations,
  } = useFetchUserParticipationsV2Query(
    {
      page,
      limit,
      name: searchName || undefined,
      status:
        activeTab === ETabsName.Sale
          ? statusFilter !== IFilterStatus.ALL
            ? statusFilter
            : undefined
          : activeTab === ETabsName.Claim
          ? IFilterStatus.TGE
          : undefined,
    },
    {
      skip: (!address && !mainAppToken) || activeTab === ETabsName.Referral,
      refetchOnFocus: true,
    },
  )

  useEffect(() => {
    if (fetchedData?.data) {
      if (page === 1) {
        setData(fetchedData.data)
      } else {
        setData(prevData => [...prevData, ...fetchedData.data])
      }
    }
  }, [fetchedData])

  useEffect(() => {
    if (mainAppToken && address) {
      fetchUserReferrals()
      fetchUserAllParticipations()
    }
    if (!mainAppToken && !address) {
      setData([])
    }
  }, [mainAppToken, address, fetchUserReferrals, fetchUserAllParticipations])

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab)
    }
  }, [state])

  const sortedArray = useMemo(() => {
    if (userReferralData) {
      let sortedArray: any = []

      sortedArray = [...userReferralData].sort((a, b) => {
        const statusA = statusOrder.indexOf(a.ido.status)
        const statusB = statusOrder.indexOf(b.ido.status)

        // Sorting in ascending order based on the order defined in statusOrder
        return statusA - statusB
      })

      sortedArray = sortItemsBySmallerTimestamp(sortedArray)

      // keep participation items status at top and then other items
      sortedArray = [
        ...sortedArray.filter(
          (item: any) => item.ido.status === IdoStatus.PARTICIPATION,
        ),
        ...sortedArray
          .filter((item: any) => item.ido.status !== IdoStatus.PARTICIPATION)
          .reverse(),
      ]

      return sortedArray
    } else return []
  }, [userReferralData])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchName(searchName)
    }, 700)

    return () => {
      clearTimeout(handler)
    }
  }, [searchName])

  useEffect(() => {
    if (debouncedSearchName) {
      setPage(1)
      setIsShowButton(true)
      if (activeTab === ETabsName.Referral) {
        fetchUserReferrals()
      } else {
        fetchUserAllParticipations()
      }
    }
  }, [
    activeTab,
    debouncedSearchName,
    fetchUserAllParticipations,
    fetchUserReferrals,
  ])

  const handleSearchChange = (e: {
    target: { value: SetStateAction<string> }
  }) => {
    setSearchName(e.target.value)
  }

  const handleSearchSubmit = (
    e: KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    setPage(1)
    setData([])
    setIsShowButton(true)
  }

  const handlePageChange = (newPage: SetStateAction<number>) => {
    setPage(newPage)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchSubmit(e)
    }
  }

  const handleTabClick = (tab: string) => {
    setActiveTab(tab as ETabsName)
    setPage(1)
    setStatusFilter(IFilterStatus.ALL)
    if (tab === ETabsName.Claim && address && mainAppToken) {
      fetchUserAllParticipations()
    }
  }

  const handleFilterChange = (newStatus: IFilterStatus) => {
    setStatusFilter(newStatus)
    setPage(1)
  }

  return (
    <div className="user-transactions-wrapper">
      <div className="user-transactions--header">
        <aside>
          <b
            className={`transaction-toggle--invest`}
            style={{
              backgroundColor: activeTab === ETabsName.Sale ? '#27B079' : '',
              border:
                activeTab === ETabsName.Sale ? 'unset' : '1px solid #392B3D',
            }}
            onClick={() => handleTabClick(ETabsName.Sale)}
          >
            <span
              style={{
                color: activeTab === ETabsName.Sale ? '#fff' : '',
              }}
            >
              Sale
            </span>
          </b>
          <b
            className={`transaction-toggle--yield`}
            style={{
              backgroundColor: activeTab === ETabsName.Claim ? '#FF7722' : '',
              border:
                activeTab === ETabsName.Claim
                  ? 'unset'
                  : '1px solid rgb(152, 156, 167)',
            }}
            onClick={() => handleTabClick(ETabsName.Claim)}
          >
            <span
              style={{
                color: activeTab === ETabsName.Claim ? '#fff' : '',
              }}
            >
              Claim
            </span>
          </b>
          <b
            className={`transaction-toggle--connectfi `}
            style={{
              backgroundColor:
                activeTab === ETabsName.Referral ? '#386a91' : '',
              border:
                activeTab === ETabsName.Referral
                  ? 'unset'
                  : '1px solid rgb(128, 156, 233)',
            }}
            onClick={() => handleTabClick(ETabsName.Referral)}
          >
            <span
              style={{
                color: activeTab === ETabsName.Referral ? '#fff' : '',
              }}
            >
              Referral
            </span>
          </b>
        </aside>
        <aside>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search"
              value={searchName}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
            />
            <button onClick={handleSearchSubmit}>
              <img src={SearchIcon} alt="SearchIcon" />
            </button>
          </div>
        </aside>
      </div>
      {activeTab === ETabsName.Sale && (
        <>
          <FilterStatusMySales
            status={statusFilter}
            handleTabChange={handleFilterChange}
            onChange={handleFilterChange}
          />
          {isLoading ? (
            <SkeletonForMySalesTable />
          ) : data.length ? (
            <MySalesTable
              isShowButton={isShowButton}
              mySales={data}
              meta={fetchedData?.meta}
              handleShowMore={() => handlePageChange(page + 1)}
            />
          ) : (
            <>
              {!isDown1000 ? (
                <NoDataTable headers={mySalesHeaders} />
              ) : (
                <NoDataBox />
              )}
            </>
          )}
        </>
      )}
      <Box
        maxWidth={isDown1536 ? '996px' : '100%'}
        width={'100%'}
        alignSelf={'center'}
        display={'flex'}
        flexDirection={'column'}
      >
        {activeTab === ETabsName.Claim &&
          (isLoading ? (
            <SkeletonForClaimTable />
          ) : data?.length ? (
            <ClaimTable
              isShowButton={isShowButton}
              meta={fetchedData?.meta}
              claimSales={data}
              handleShowMore={() => handlePageChange(page + 1)}
            />
          ) : (
            <>
              {!isDown1000 ? (
                <NoDataTable headers={claimHeaders} />
              ) : (
                <NoDataBox />
              )}
            </>
          ))}
      </Box>
      {activeTab === ETabsName.Referral &&
        (userReferralDataLoading ? (
          <SkeletonForMyReferralsTable />
        ) : sortedArray?.length ? (
          <MyReferralsTable isShowButton={isShowButton} myReferrals={sortedArray} />
        ) : (
          <>
            {!isDown1000 ? (
              <NoDataTable headers={referralsHeaders} />
            ) : (
              <NoDataBox />
            )}
          </>
        ))}
    </div>
  )
}
export default UserTransactions
