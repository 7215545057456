//@ts-nocheck
import { useLocation, useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { useWallet } from "@solana/wallet-adapter-react";
// import { useAuth } from "@/providers/AuthProvider";
// import { useBalanceSol } from "@/solana/useBalanceSol";
import { LogoIcon } from "../icons/LogoIcon";
import ConnectWalletModal from "./connectWallet/ConnectWalletModal";
import { useWalletFunctions } from "./connectWallet/hooks/useWalletFunctions";
import ModalSwitchNetwork from "./connectWallet/ModalSwitchNetwork";
import renderChainInfo from "./renderChainInfo";
import { getCurrency, NETWORKS_CURRENCY, NETWORKS_NAME } from "../../utils/network";
import { useScreen } from "../../utils/useScreen";
import { PATHS } from "../../constants/config";
import { formatAddress, formatRoundDown } from "../../utils/function";
import lockIcon from "../../anyfi-src/assets/icons/lock.svg";
import chevronIcon from "../../anyfi-src/assets/icons/chevron.svg";
import arrowUpIcon from "../../anyfi-src/assets/icons/up-icon.svg";
import solanaIcon from "../../anyfi-src/assets/icons/solana-active.svg";
import logoPng from "../../assets/ape-terminal-white.svg";
import Navbar from "../Navbar";

const Header = () => {
  const { connected: isConnectedSol, publicKey } = useWallet();
  const location = useLocation();
  const { id } = useParams()
  // const { balanceSol } = useBalanceSol();
  const { balanceSol } = {balanceSol: 0};
  const { pathname } = location;
  const isPathnameLaunchpad = pathname === PATHS.launchpad || pathname === `${PATHS.launchpad}/${id}`;
  const { isConnected, address, chainId } = useAccount();
  const { lg } = useScreen();
  // const { balanceAddress } = useAuth();
  const { balanceAddress } = { balanceAddress: '' };
  const {
    selectedNetwork,
    selectedConnector,
    openModal,
    openSwitchNetwork,
    openDisconnect,
    sortedConnectors,
    handleClose,
    handleLogout,
    handleNetworkClick,
    handleConnectorClick,
    handleConnectWallet,
    handleSwitchNetwork,
    setOpenModal,
    setOpenSwitchNetwork,
    setOpenDisconnect,
    handleLogoutSol,
    isLoadingConnect,
  } = useWalletFunctions();

  const isConnectSolana = isConnectedSol && publicKey.toString();
  const isConnectWeb3 = isConnected && address;

  const handleOpenSwitchNetwork =() =>{
    if(isConnectSolana || isConnectWeb3){
      setOpenSwitchNetwork(true)
    }
  }

  return (
    <header
      className={`${
        isPathnameLaunchpad ? "launchpad" : ""
      } wallet-header`}
    >
      <div className="wallet-header-wrapper">
        <nav>
          <a className="mobile-logo" href={PATHS.home}>
            {/* <LogoIcon /> */}
            <img src={logoPng} alt="" />
          </a>
          {lg && <Navbar className="" isLoading={false} giftBannerHeight={"16px"} />}
        </nav>
      </div>
      <ConnectWalletModal
        opened={openModal}
        isLoadingConnect={isLoadingConnect}
        handleClose={handleClose}
        handleNetworkClick={handleNetworkClick}
        handleConnectorClick={handleConnectorClick}
        selectedNetwork={selectedNetwork}
        selectedConnector={selectedConnector}
        sortedConnectors={sortedConnectors}
        handleConnectWallet={handleConnectWallet}
      />
      <ModalSwitchNetwork
        opened={openSwitchNetwork}
        isLoadingConnect={isLoadingConnect}
        selectedNetwork={selectedNetwork}
        handleSwitchNetwork={handleSwitchNetwork}
        handleNetworkClick={handleNetworkClick}
        handleClose={handleClose}
      />
    </header>
  );
};

export default Header;
