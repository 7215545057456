import React from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

interface IProjectTabs {
  handleTabChange: (value: number) => void
  tab: number
  showClaim: boolean
  showIdoDetails: boolean
  isResearchProject?: boolean
}

const ProjectTabs: React.FC<IProjectTabs> = ({
  handleTabChange,
  tab,
  showClaim,
  showIdoDetails,
  isResearchProject,
}) => {
  const activeTabBorder = '0.5px solid rgba(255, 255, 255, 0.16)'
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const isDown410 = useMediaQuery('(max-width:410px)')
  const isDown350 = useMediaQuery('(max-width:350px)')

  return (
    <Box
      sx={{
        // minWidth: '300px',
        // width: isDown1200 ? `calc(${window.innerWidth}px)` : '100%',
        width: isDown1200 || showClaim ? '100%' : '70%',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      }}
      my={isDown1200 ? '32px' : '48px'}
    >
      <Grid
        container
        height={'48px'}
        borderRadius={'25px'}
        bgcolor={'#0F1113'}
        minWidth={'max-content'}
        sx={{
          flexFlow: 'nowrap',
          // marginRight: '20px',
        }}
      >
        <Grid
          item
          height={'48px'}
          xs={
            !showIdoDetails && showClaim
              ? 6
              : showClaim
              ? 4
              : showIdoDetails
              ? 6
              : 12
          }
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          border={tab === 0 ? activeTabBorder : 'none'}
          onClick={() => handleTabChange(0)}
          sx={{
            cursor: 'pointer',
            borderRadius: '25px',
            padding:
              isDown410 && showClaim
                ? '12px 10px 12px 10px'
                : '12px 24px 13px 24px',
            backgroundColor: tab === 0 ? 'rgba(111, 235, 124, 0.03)' : 'none',
            minWidth: 'fit-content',
            '&:hover > .project-details-tab': {
              color: tab === 0 ? '#63EA71' : '#ffffff',
            },
          }}
        >
          <Typography
            fontSize={isDown350 && showClaim ? '12px' : '15px'}
            fontWeight={400}
            lineHeight={'22.5px'}
            fontFamily={'Inter'}
            color={tab === 0 ? '#63EA71' : tab === 1 ? '#B1B4B3' : '#B1B4B3'}
            className="project-details-tab"
            style={{ transition: 'color 0.3s' }}
          >
            Project Details
          </Typography>
        </Grid>

        {showIdoDetails && (
          <Grid
            item
            height={'48px'}
            xs={showClaim ? 4 : 6}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            border={tab === 1 ? activeTabBorder : 'none'}
            onClick={() => handleTabChange(1)}
            sx={{
              cursor: 'pointer',
              borderRadius: '25px',
              padding:
                isDown410 && showClaim
                  ? '12px 10px 12px 10px'
                  : '12px 24px 13px 24px',
              backgroundColor: tab === 1 ? 'rgba(111, 235, 124, 0.03)' : 'none',
              minWidth: 'fit-content',
              '&:hover > .project-details-tab': {
                color: tab === 1 ? '#63EA71' : '#ffffff',
              },
            }}
          >
            <Typography
              fontSize={isDown350 && showClaim ? '12px' : '15px'}
              fontWeight={400}
              lineHeight={'22.5px'}
              fontFamily={'Inter'}
              color={tab === 1 ? '#63EA71' : tab === 0 ? '#B1B4B3' : '#B1B4B3'}
              className="project-details-tab"
              style={{ transition: 'color 0.3s' }}
            >
              {isResearchProject ? "Fair Launch Details" : "IDO Details"}
            </Typography>
          </Grid>
        )}
        {showClaim && (
          <Grid
            item
            height={'48px'}
            xs={!showIdoDetails ? 6 : 4}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            border={tab === 2 ? activeTabBorder : 'none'}
            onClick={() => handleTabChange(2)}
            sx={{
              cursor: 'pointer',
              borderRadius: '25px',
              padding:
                isDown410 && showClaim
                  ? '12px 10px 12px 10px'
                  : '12px 24px 13px 24px',
              backgroundColor: tab === 2 ? '#000' : 'none',
              minWidth: 'fit-content',
              '&:hover > .project-details-tab': {
                color: tab === 2 ? '#63EA71' : '#ffffff',
              },
            }}
          >
            <Typography
              fontSize={isDown350 && showClaim ? '12px' : '15px'}
              fontWeight={400}
              lineHeight={'22.5px'}
              fontFamily={'Inter'}
              color={tab === 2 ? '#63EA71' : tab === 0 ? '#B1B4B3' : '#B1B4B3'}
              className="project-details-tab"
              style={{ transition: 'color 0.3s' }}
            >
              Claim
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default ProjectTabs
