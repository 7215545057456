import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  useMediaQuery,
} from '@mui/material'
import {
  StyledHeadTableCell,
  FixedHeadTableCell,
  StyledBodyTableCell,
  FixedBodyTableCell,
} from './MySalesTable'

const SkeletonForMyReferralsTable = () => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  const skeletonRows = Array(5).fill(null)

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              maxHeight: '408px',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow>
                {isDown800 ? (
                  <FixedHeadTableCell>Projects</FixedHeadTableCell>
                ) : (
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                )}
                <StyledHeadTableCell>People used my link</StyledHeadTableCell>
                <StyledHeadTableCell>Multiplier</StyledHeadTableCell>
                <StyledHeadTableCell>Status</StyledHeadTableCell>
                <StyledHeadTableCell>My Referral links</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {skeletonRows.map((_, index) => (
                <TableRow key={index}>
                  {isDown800 ? (
                    <FixedBodyTableCell>
                      <Skeleton
                        variant="rounded"
                        width={40}
                        height={40}
                        sx={{ 
                          borderRadius: '10px',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)'
                        }}
                      />
                    </FixedBodyTableCell>
                  ) : (
                    <StyledBodyTableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          minWidth: '220px',
                        }}
                      >
                        <Skeleton
                          variant="rounded"
                          width={40}
                          height={40}
                          sx={{ 
                            borderRadius: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)'
                          }}
                        />
                        <Box ml={'12px'}>
                          <Skeleton
                            variant="text"
                            width={120}
                            height={24}
                            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                          />
                        </Box>
                      </Box>
                    </StyledBodyTableCell>
                  )}
                  <StyledBodyTableCell>
                    <Skeleton
                      variant="text"
                      width={60}
                      height={24}
                      sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                    />
                  </StyledBodyTableCell>
                  <StyledBodyTableCell>
                    <Skeleton
                      variant="text"
                      width={40}
                      height={24}
                      sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                    />
                  </StyledBodyTableCell>
                  <StyledBodyTableCell>
                    <Skeleton
                      variant="rounded"
                      width={88}
                      height={28}
                      sx={{ 
                        borderRadius: '80px',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)'
                      }}
                    />
                  </StyledBodyTableCell>
                  <StyledBodyTableCell>
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        variant="circular"
                        width={16}
                        height={16}
                        sx={{ 
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          mr: 1
                        }}
                      />
                      <Skeleton
                        variant="text"
                        width={120}
                        height={24}
                        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                      />
                      <Skeleton
                        variant="circular"
                        width={16}
                        height={16}
                        sx={{ 
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          ml: 1
                        }}
                      />
                    </Box>
                  </StyledBodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={2} textAlign="center">
        <Skeleton
          variant="rounded"
          width="100%"
          height={48}
          sx={{ 
            borderRadius: '24px',
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          }}
        />
      </Box>
    </Box>
  )
}

export default SkeletonForMyReferralsTable
