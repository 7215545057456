import { PieChartOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { isEmpty, toNumber, toString } from 'lodash'
import { useMemo, useState } from 'react'
import arrowDownIcon from '../../assets/revamp/icons/arrow_down.svg'
import arrowUpIcon from '../../assets/revamp/icons/arrow_up.svg'
import chevronIcon from '../../assets/revamp/icons/chevron.svg'
import infoIcon from '../../assets/revamp/icons/info.svg'
import Icon from '../../components/common/Icon'
import {
  ETierRange,
  ITokenAssetInfo,
  ITokenTimeSeries,
} from '../../services/modules/portfolio-manager'
import { numberFormat } from '../../utils/function'
import TokenAllowcationChart from './TokenAllowcationChart'
import TotalAssetsChart from './TotalAssetsChart'

interface AssetsOverviewProps {
  assets: ITokenAssetInfo[]
  assetsChart: ITokenTimeSeries | null
  onFilter: (filter: string, reset: boolean) => void
}

const calculatedAssets = (assets: ITokenAssetInfo[]): number => {
  if (!assets || !assets.length) return 0

  let total = 0
  assets.forEach(asset => {
    total += parseFloat(asset.usd_value || '0')
    // if (asset.references && asset.references.length) {
    //   total += calculatedAssets(asset.references)
    // }
  })
  return total
}

const AssetsOverview: React.FC<AssetsOverviewProps> = props => {
  const { assets, assetsChart, onFilter } = props
  const [totalAssets, setTotalAssets] = useState<number>(0)
  const [totalNFT, setTotalNFT] = useState<number>(0)
  const [totalToClaim, setTotalToClaim] = useState<number>(0)
  const [currentFilter, setFilter] = useState<string>('1d')
  const [isOpenModal, setOpenModal] = useState<boolean>(false)

  useMemo(() => {
    const total = calculatedAssets(assets)
    setTotalAssets(total)
  }, [assets])

  const calculateAssetsChange = () => {
    if (!assetsChart) return null

    let values = Object.values(assetsChart.timeSeries)

    const firstValue = values?.[0]
    const lastValue = values[values.length - 1]

    const isIncrease = lastValue - firstValue > 0

    return (
      <span className={isIncrease ? 'increase' : ''}>
        ${Math.abs(toNumber(numberFormat(lastValue - firstValue, 2, 2)))} (
        <Icon src={isIncrease ? arrowUpIcon : arrowDownIcon} />
        {((Math.abs(lastValue - firstValue) * 100) / firstValue).toFixed(2)}%)
        Today
      </span>
    )
  }

  const handleChangeChartTier = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    onFilter(event.target.value, true)
    setFilter(event.target.value)
  }

  const showAllTokens = () => {
    setOpenModal(true)
  }

  return (
    <section className="assets-overview">
      <div className="assets-overview-wrapper">
        <div className="assets-overview--total">
          <div className="assets-overview--total--asset">
            <b>Total Assets</b>
            <span>${numberFormat(totalAssets, 2, 2)}</span>
          </div>
          <div className="assets-overview--total--asset">
            <b>NFT Assets</b>
            <span>${numberFormat(totalNFT, 2, 2)}</span>
          </div>
          <div className="assets-overview--total--asset">
            <b>To Claim</b>
            <span>${numberFormat(totalToClaim, 2, 2)}</span>
          </div>
        </div>
        <div className="assets-overview--graph">
          <div className="graph">
            <div className="graph--header">
              <aside>
                <div>
                  <p>Portfolio</p>
                  <Icon src={infoIcon} />
                  <p>Not counting NFTs</p>
                </div>
                <b>${numberFormat(totalAssets, 2, 2)}</b>

                {calculateAssetsChange()}
              </aside>
              <span className="switch">
                <select
                  name=""
                  id=""
                  style={{ backgroundImage: `url(${chevronIcon})` }}
                  onChange={handleChangeChartTier}
                >
                  <option value={ETierRange.day}>1D</option>
                  <option value={ETierRange.week}>1W</option>
                  <option value={ETierRange.month}>1M</option>
                  <option value={ETierRange.year}>1Y</option>
                </select>
              </span>
            </div>
            <div style={{ height: 197 }}>
              {assetsChart ? (
                <TotalAssetsChart
                  data={assetsChart}
                  xAxisFormat={currentFilter === '1d' ? 'hh:mm' : 'D MMM'}
                />
              ) : null}
            </div>
          </div>
          <div className="pie">
            <div className="pie--header">
              <span>Token Allocation</span>
              <span onClick={showAllTokens}>See All</span>
            </div>
            {assets && !isEmpty(assets) ? (
              <TokenAllowcationChart data={assets as any[]} />
            ) : null}
            <div className="pie--footer">
              <ul>
                {assets &&
                  assets
                    .sort(
                      (a, b) =>
                        parseFloat(b.usd_value || '0') -
                        parseFloat(a.usd_value || '0'),
                    )
                    .map((asset, index) => {
                      if (index > 3) return null

                      return (
                        <li key={index}>
                          <span className="token">{asset.token.denom}</span>
                          <span>
                            {(
                              (parseFloat(asset.usd_value || '0') * 100) /
                              totalAssets
                            ).toFixed(2)}
                            %
                          </span>
                        </li>
                      )
                    })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={
          <>
            <PieChartOutlined />
            <p>Token Allocation</p>
          </>
        }
        className="all-assets-modal"
        centered
        open={isOpenModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <div className="all-assets-modal__list">
          <ul>
            {assets &&
              assets
                .sort(
                  (a, b) =>
                    parseFloat(b.usd_value || '0') -
                    parseFloat(a.usd_value || '0'),
                )
                .map((asset, index) => {
                  return (
                    <li key={index}>
                      <div>
                        <img
                          src={asset.token.image ?? ''}
                          alt={toString(asset.token.id)}
                        />
                        <span>
                          {numberFormat(+asset.balance, 2, 2)}{' '}
                          {asset.token.denom}
                        </span>
                      </div>
                      <div>
                        <span>${numberFormat(+asset.usd_value, 2, 2)}</span>
                        <span>
                          {(
                            (parseFloat(asset.usd_value || '0') * 100) /
                            totalAssets
                          ).toFixed(2)}
                          %
                        </span>
                      </div>
                    </li>
                  )
                })}
          </ul>
        </div>
        <div className="all-assets-modal__footer">
          <button onClick={() => setOpenModal(false)}>Close</button>
        </div>
      </Modal>
    </section>
  )
}

export default AssetsOverview
