//@ts-nocheck
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { useWallet } from '@solana/wallet-adapter-react'
// import { useAuth } from "@/providers/AuthProvider";
// import { useBalanceSol } from "@/solana/useBalanceSol";
import { LaunchpadIcon } from '../icons/LaunchpadIcon'
import { MenuIcon } from '../icons/MenuIcon'
import { UserPortalIcon } from '../icons/UserPortalIcon'
import { PortfolioIcon } from '../icons/PortfolioIcon'
import { CloseIcon } from '../icons/CloseIcon'
import { LogoIcon } from '../icons/LogoIcon'
import { sideMenu } from './config'
import ConnectWalletModal from './/connectWallet/ConnectWalletModal'
import ModalSwitchNetwork from './/connectWallet/ModalSwitchNetwork'
import renderChainInfo from './/renderChainInfo'
import { useWalletFunctions } from './/connectWallet/hooks/useWalletFunctions'
import { PATHS, labelNavbar } from '../../constants/config'
import { formatAddress, formatRoundDown } from '../../utils/function'
import {
  getCurrency,
  NETWORKS_CURRENCY,
  NETWORKS_NAME,
} from '../../utils/network'
import chevronIcon from '../../assets/revamp/icons/chevron.svg'
import arrowUpIcon from '../../assets/revamp/icons/up-icon.svg'
import solanaIcon from '../../assets/revamp/icons/solana-active.svg'
import lockIcon from '../../assets/revamp/icons/lock.svg'
import logoPng from '../../assets/ape-terminal-white.svg'
import Navbar from '../../components/Navbar'

const SidebarMobile = () => {
  const [isNavOpened, setIsNavOpened] = useState(false)
  const height = window.innerHeight
  // const { balanceAddress } = useAuth();
  const { balanceAddress } = { balanceAddress: '' }
  const { isConnected, address, chainId } = useAccount()
  const { connected: isConnectedSol, publicKey } = useWallet()
  // const { balanceSol } = useBalanceSol();
  const { balanceSol } = { balanceSol: 0 }

  const {
    selectedNetwork,
    selectedConnector,
    openModal,
    openSwitchNetwork,
    openDisconnect,
    sortedConnectors,
    handleClose,
    handleLogout,
    handleNetworkClick,
    handleConnectorClick,
    handleConnectWallet,
    handleSwitchNetwork,
    setOpenModal,
    setOpenSwitchNetwork,
    setOpenDisconnect,
    handleLogoutSol,
  } = useWalletFunctions()

  const isConnectSolana = isConnectedSol && publicKey.toBase58()
  const isConnectWeb3 = isConnected && address

  const toggleNav = () => {
    setIsNavOpened(isOpened => !isOpened)
  }
  return (
    <header className="mobile-header">
      <div className="mobile-header-wrapper">
        <nav>
          <ul>
            <li>
              <NavLink
                to={PATHS.userPortal}
                className={({ isActive }) => `${isActive && 'active'}`}
              >
                <UserPortalIcon />
                <span>User Portal</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATHS.portfolio}
                className={({ isActive }) => `${isActive && 'active'}`}
              >
                <PortfolioIcon />
                <span>Portfolio Manager</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATHS.investmentPortal}
                className={({ isActive }) => `${isActive && 'active'}`}
              >
                <LaunchpadIcon />
                <span>Investment Portal</span>
              </NavLink>
            </li>
            <li>
              <a onClick={toggleNav} className="mobile-toggle">
                <MenuIcon />
                <span>Menu</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {isNavOpened && (
        <div className="navbar-list" style={{ height }}>
          <div className="header-navbar">
            <a className="logo" href={PATHS.home}>
              <img src={logoPng} alt="" />
            </a>
            <div className="close-icon" onClick={toggleNav}>
              <CloseIcon />
            </div>
          </div>
          <nav>
            <ul>
              {sideMenu.menuHeader
                .filter(
                  navItem =>
                    navItem.label !== labelNavbar.home &&
                    navItem.label !== labelNavbar.userPortal &&
                    navItem.label !== labelNavbar.portfolio,
                )
                .map((navItem, id) => (
                  <li key={id}>
                    <NavLink
                      to={navItem.link}
                      className={({ isActive }) => `${isActive && 'active'}`}
                    >
                      {navItem.icon}
                      <span>{navItem.label}</span>
                    </NavLink>
                  </li>
                ))}
              {sideMenu.ProductsMenu.filter(
                navItem =>
                  navItem.label !== labelNavbar.launchpad &&
                  navItem.label !== labelNavbar.connectFi,
              ).map((navItem, id) => (
                <li key={id}>
                  <NavLink
                    to={navItem.link}
                    className={({ isActive }) => `${isActive ? 'active' : ''}`}
                  >
                    {navItem.icon}
                    <span>{navItem.label}</span>
                    {navItem.textNavComing && <em>{navItem.textNavComing}</em>}
                  </NavLink>
                </li>
              ))}
              {sideMenu.AccountandTools.map((navItem, id) => (
                <li key={id}>
                  <NavLink
                    to={`${navItem.link}`}
                    className={({ isActive }) => `${isActive && 'active'}`}
                  >
                    {navItem.icon}
                    <span>{navItem.label}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <div className="button-wrapper-mobile">
            {/* {isConnectWeb3 && (
              <button
                onClick={() => setOpenDisconnect(!openDisconnect)}
                className="button transparent"
              >
                <span>{formatAddress(address, 6, 6)}</span>
                <span className="border"></span>
                <span className="balance">
                  {' '}
                  {formatRoundDown(balanceAddress, 5)}{' '}
                  {chainId && getCurrency(chainId.toString())}
                </span>
                {openDisconnect ? (
                  <img
                    className="wallet-header--dropdown"
                    src={arrowUpIcon}
                    alt=""
                  />
                ) : (
                  <img
                    className="wallet-header--dropdown"
                    src={chevronIcon}
                    alt=""
                  />
                )}
              </button>
            )}

            {isConnectSolana && (
              <button
                onClick={() => setOpenDisconnect(!openDisconnect)}
                className="button transparent"
              >
                <span>{formatAddress(publicKey.toString(), 6, 6)}</span>
                <span className="border"></span>
                <span className="balance">
                  {' '}
                  {formatRoundDown(balanceSol, 5)} {NETWORKS_CURRENCY.SOLANA}
                </span>
                {openDisconnect ? (
                  <img
                    className="wallet-header--dropdown"
                    src={arrowUpIcon}
                    alt=""
                  />
                ) : (
                  <img
                    className="wallet-header--dropdown"
                    src={chevronIcon}
                    alt=""
                  />
                )}
              </button>
            )}
            {!isConnectWeb3 && !isConnectSolana && (
              <button
                onClick={() => setOpenModal(true)}
                className="button transparent"
              >
                <img src={lockIcon} alt="BSC Wallet" />
                <span>Connect Wallet</span>
              </button>
            )}
            {openDisconnect && (
              <div className="disconnect w-full">
                <button
                  onClick={isConnectSolana ? handleLogoutSol : handleLogout}
                  className="button disconect-mobile transparent w-full"
                >
                  <span>Disconnect</span>
                </button>
              </div>
            )} */}
            <Navbar isLoading={false} giftBannerHeight={'16px'} />
          </div>
        </div>
      )}
      <ConnectWalletModal
        opened={openModal}
        handleClose={handleClose}
        handleNetworkClick={handleNetworkClick}
        handleConnectorClick={handleConnectorClick}
        selectedNetwork={selectedNetwork}
        selectedConnector={selectedConnector}
        sortedConnectors={sortedConnectors}
        handleConnectWallet={handleConnectWallet}
      />
      <ModalSwitchNetwork
        opened={openSwitchNetwork}
        chainId={chainId}
        selectedNetwork={selectedNetwork}
        handleSwitchNetwork={handleSwitchNetwork}
        handleNetworkClick={handleNetworkClick}
        handleClose={handleClose}
      />
    </header>
  )
}
export default SidebarMobile
