import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material'
import { ChipText } from '../../components/NewUpcomingLaunchCard'
import { ReactComponent as WebIcon } from '../../assets/web.svg'
import { ReactComponent as XIcon } from '../../assets/x.svg'
import { ReactComponent as TelegramIcon } from '../../assets/telegram3.svg'
import { ReactComponent as DiscordIcon3 } from '../../assets/discord2.svg'
import { ReactComponent as RedditIcon } from '../../assets/reddit.svg'
import { ReactComponent as MediumIcon } from '../../assets/medium.svg'
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg'

import YouTubeIcon from '@mui/icons-material/YouTube'
import { getReplacedCdnEndpoint, insertSeparator } from '../../utils'
import { ReactComponent as GithubIcon2 } from '../../assets/github.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { ReactComponent as ThreeDots } from '../../assets/three_dots.svg'
import { width } from '@mui/system'

interface IBanner {
  socialLinks: string[]
  website: string
  name: string
  intro: string
  introMeta?: string
  symbol: string
  logoUrl: string
  videoUrl?: string
  projectImageUrl: string
  tags: string[]
}

const Banner: React.FC<IBanner> = ({
  socialLinks,
  website,
  name,
  intro,
  introMeta,
  symbol,
  logoUrl,
  videoUrl,
  projectImageUrl,
  tags,
}) => {
  const theme = useTheme()
  const isDown1200 = useMediaQuery(theme.breakpoints.down(1200))
  const isDown600 = useMediaQuery(theme.breakpoints.down(600))
  const isDown400 = useMediaQuery(theme.breakpoints.down(400))
  const links = [website, ...socialLinks]

  const maxIntroLength = 512

  const isIntroLong = intro.length > maxIntroLength
  const shortIntro = intro.slice(0, maxIntroLength)
  const remainingIntro = intro.slice(maxIntroLength)

  const [showMore, setShowMore] = React.useState(false)

  const [showTooltip, setShowTooltip] = React.useState(false)
  const [isTagsHidden, setIsTagsHidden] = React.useState(true)
  const videoRef = React.useRef<any>();

  useEffect(() => {
    setIsTagsHidden(false);
  }, [name, website]);

  return (
    <Box
      sx={{
        background: isDown1200
          ? '#fff'
          : 'linear-gradient(142.67deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.09) 51.04%, rgba(255, 255, 255, 0.16) 100%)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        border: '1px solid rgba(0,0,0,0.2)',
        marginBottom: isDown1200 ? '24px' : '',
      }}
    >
      <Box
        sx={{
          background: isDown1200 ? '#000000' : '#0F1113',
          borderRadius: '16px',
          border: 'none',
          width: `calc(100% - 1px)`,
          margin: '0.5px',
        }}
      >
        <Box
          position={'relative'}
          sx={{
            width: '100%',
            // height: isDown1200 ? '351px' : '420px',
            height: isDown400
              ? '200px'
              : isDown600
              ? '250px'
              : isDown1200
              ? '335px'
              : '420px',
            overflow: 'hidden',
            display: 'flex',
            borderRadius: '12px',
            padding: isDown1200 ? '16px' : '16px',
          }}
        >
          {
            videoUrl ? (
              <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                <video
                  ref={videoRef}
                  onClick={() => {
                    if(!videoRef || !videoRef.current) return;
                    videoRef.current.muted = false;
                  }}
                  autoPlay
                  muted
                  playsInline
                  controls
                  style={{
                    height: '100%',
                    width: 'auto',
                    objectFit: 'cover',
                  }}
                  poster={getReplacedCdnEndpoint(projectImageUrl)}
                  onPlay={() => {
                    setIsTagsHidden(true);
                  }}
                >
                  <source src={getReplacedCdnEndpoint(videoUrl)} type="video/mp4" />
                </video>
              </Box>
            ) : (
              <img
                src={getReplacedCdnEndpoint(projectImageUrl)}
                style={{
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '12px',
                  height: isDown400
                    ? '200px'
                    : isDown600
                    ? '250px'
                    : isDown1200
                    ? '335px'
                    : '404px',
                  border: isDown1200
                    ? '2px solid rgba(255, 255, 255, 0.16)'
                    : 'none',
                  // position: 'absolute',
                }}
                alt="launch-banner"
              />
            )
          }
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              paddingBottom: '12px',
              marginRight: isDown1200 ? '16px' : '24px',
              zIndex: 1,
              display: isTagsHidden ? 'none' : 'block',
            }}
          >
            <Grid container>
              {tags?.map((tag, index) => (
                <ChipText key={index} text={tag} color={'#B1B4B3'} bgColor={'#000'} isBigger />
              ))}
            </Grid>
          </Box>
        </Box>

        {/* Position logo, name, and symbol below the image */}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingX={isDown1200 ? '16px' : '24px'}
          my={'16px'}
          gap={'16px'}
          flexWrap={'wrap'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'row'}
          >
            <img
              src={getReplacedCdnEndpoint(logoUrl)}
              alt="launch-logo"
              style={{
                width: '56px',
                height: '56px',
                borderRadius: '12px',
                objectFit: 'contain',
                border: '0.5px solid #1D1F21',
              }}
            />
            <Box display={'flex'} flexDirection={'column'} ml={'16px'}>
              <Typography
                fontWeight={isDown1200 ? 500 : 600}
                fontSize={isDown1200 ? 17 : 20}
                lineHeight={'28px'}
                letterSpacing={'0px'}
                textAlign={'left'}
                fontFamily={'Hellix'}
                color="#FFF"
                component={'h1'}
              >
                {name}
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={isDown1200 ? 15 : 16}
                lineHeight={'24px'}
                letterSpacing={'0px'}
                textAlign={'left'}
                fontFamily={'Inter'}
                color="#767676"
              >
                {symbol}
              </Typography>
            </Box>
          </Box>

          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'row'}
            gap={'4px'}
          >
            {links.slice(0, 2).map((link, index) => (
              <RenderIcon key={index} url={link} />
            ))}
            {links.length > 2 && (
              <Tooltip
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    {links.slice(2).map((link, index) => (
                      <RenderIcon key={index} url={link} />
                    ))}
                  </Box>
                }
                placement="bottom-start"
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '32px',
                    width: '48px',
                    border: '1px solid #1D1F21',
                    borderRadius: '60px',
                    '& svg circle': {
                      transition: 'all 0.3s',
                    },
                    '&:hover svg circle': {
                      fill: '#ffffff',
                    },
                  }}
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  {React.createElement(ThreeDots, {
                    className: 'three-dots',
                  })}
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>

        {/* Project 3 liner intro, if length exceeds three lines then show three dots ... and show more text below it with expand more or less icon */}

        <Box
          sx={{
            paddingX: isDown1200 ? '16px' : '24px',
            paddingBottom: isDown1200 ? '16px' : '32px',
            textAlign: 'left',
            color: '#b1b4b3',
            fontFamily: 'Inter',
            fontSize: '15px',
            fontWeight: 400,
          }}
        >
          {isIntroLong ? (
            <>
              {showMore ? intro : `${shortIntro}...`}
              <Box
                sx={{
                  cursor: 'pointer',
                  color: '#fff',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 500,
                  display: 'flex',
                  paddingTop: '8px',
                  width: 'fit-content',
                }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? 'Show Less' : 'Show More'}
                {showMore ? (
                  <ExpandLessIcon sx={{ ml: '6px' }} />
                ) : (
                  <ExpandMoreIcon sx={{ ml: '6px' }} />
                )}
              </Box>
            </>
          ) : (
            insertSeparator(intro.split("\\n"), <><br /></>)
          )}
          <Box mt={2}>
            <>{introMeta || "For a chance to participate in this crypto IDO please check the pre-sale details below."}</>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Banner

interface RenderIconProps {
  url: string
}

export const RenderIcon: React.FC<RenderIconProps> = ({ url }) => {
  const openLinkInNewTab = () => {
    window.open(url, '_blank')
  }

  const iconProps = {
    height: '24px',
    width: '24px',
    color: '#767676',
    transition: 'all 0.3s',
  }

  const getIcon = (icon: any, imageSrc?: any) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
        width: '48px',
        border: '1px solid #1D1F21',
        borderRadius: '60px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        '& * path': {
          transition: 'all 0.3s',
        },
        ':hover': {
          backgroundColor: '#0F1113',
        },
        ':hover .white_hover_svg:not([fill="none"])': {
          fill: '#ffffff !important',
        },
        ':hover .white_hover_svg[fill="none"] path': {
          fill: '#ffffff !important',
        },
      }}
      className="white_hover_svg__wrapper"
    >
      <Tooltip title={url}>
        {imageSrc ? (
          <img
            style={{ ...iconProps, height: '24px' }}
            src={getReplacedCdnEndpoint(imageSrc)}
            alt={url}
            onClick={openLinkInNewTab}
          />
        ) : (
          React.createElement(icon, {
            className: 'white_hover_svg',
            onClick: openLinkInNewTab,
            style: iconProps,
          })
        )}
      </Tooltip>
    </Box>
  )

  if (url.includes('x.com') || url.includes('twitter')) {
    return getIcon(XIcon)
  } else if (url.includes('t.me')) {
    return getIcon(TelegramIcon)
  } else if (url.includes('youtubbe')) {
    return getIcon(YouTubeIcon)
  } else if (url.includes('insta')) {
    return getIcon(InstagramIcon)
  } else if (url.includes('reddit')) {
    return getIcon(RedditIcon)
  } else if (url.includes('discord')) {
    return getIcon(DiscordIcon3)
  } else if (url.includes('medium')) {
    return getIcon(MediumIcon)
  } else if (url.includes('github')) {
    return getIcon(GithubIcon2)
  } else {
    return getIcon(WebIcon, null)
  }
}
