//@ts-nocheck
import { MetaMask, WalletConnect, sleep } from "../constants/pool";

export const getSortedConnectorsAndChainId = (
  connectors,
  networks,
  selectedNetwork
) => {
  const sortedConnectors = connectors
    .filter((connector) => [WalletConnect, MetaMask].includes(connector.name))
    .reverse();

  const selectedNetworkInfo = Object.values(networks).find(
    (item) => item.networkName === selectedNetwork
  );

  const selectedChainId = selectedNetworkInfo
    ? Number(selectedNetworkInfo.chainId)
    : null;

  return { sortedConnectors, selectedChainId };
};

export const connectAndSignIn = async (
  connectorName,
  selectedNetwork,
  selectedChainId,
  sortedConnectors,
  connectAsync,
  signMessageAsync,
  handleLogin,
  dataPublickey,
  address
) => {
  if (!selectedNetwork || !connectorName) return;

  try {
    const connector = sortedConnectors.find(
      (connector) => connector.name === connectorName
    );

    const connectionResult = await connectAsync({
      connector,
      chainId: selectedChainId,
    });

    await sleep(1);

    const signature = await signMessageAsync({
      message: dataPublickey,
    });

    await handleLogin(signature, connectionResult.accounts[0] || address, 1);
  } catch (error) {
    console.error("Error connecting to wallet:", error);
  }
};
export const connectAndSignInSwitchNetwork = async (
  selectedNetwork,
  selectedChainId,
  dataPublickey,
  address,
  handleLogin,
  switchChainAsync,
  signMessageAsync,
  sleep,
) => {
  if (!selectedNetwork) return;

  try {
    await switchChainAsync({
      chainId: selectedChainId,
    });
    await sleep(1);
    const signature = await signMessageAsync({
      message: dataPublickey,
    });

    await handleLogin(signature, address, 1);
  } catch (error) {
    console.error("Error connecting to wallet:", error);
  }
};
