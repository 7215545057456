import { useMemo, useState } from 'react'
import {
  ITokenAssetInfo,
  useFetchTokenInfoQuery,
} from '../../services/modules/portfolio-manager'
import WalletItem from './WalletItem'
import WalletTitle from './WalletTitle'

interface WalletOverviewProps {
  assets: ITokenAssetInfo[]
}

const calculatedAssets = (assets: ITokenAssetInfo[]): number => {
  if (!assets || !assets.length) return 0

  let total = 0
  assets.forEach(asset => {
    total += parseFloat(asset.usd_value || '0')
    // if (asset.references && asset.references.length) {
    //   total += calculatedAssets(asset.references)
    // }
  })
  return total
}

const WalletOverview: React.FC<WalletOverviewProps> = props => {
  const { assets } = props
  const [totalAssets, setTotalAssets] = useState<number>(0)
  const [openingTokenId, setOpeningTokenId] = useState<number | string>('')

  useMemo(() => {
    const total = calculatedAssets(assets)
    setTotalAssets(total)
  }, [assets])

  return (
    <section className="wallet-overview">
      <div className="wallet-overview-wrapper">
        <WalletTitle count={assets.length} total={totalAssets} />
        <div className="wallet-overview--accordions">
          <div className="wallet-overview--accordions--header">
            <b>Token</b>
            <b>Holdings</b>
          </div>
          {assets.map((asset, index) => (
            <WalletItem
              asset={asset}
              id={asset?.token?.id ?? ''}
              key={`${asset.token.id}_${index}`}
              openingTokenId={openingTokenId}
              changeOpeningTokenId={setOpeningTokenId}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default WalletOverview
