import { Tooltip } from 'antd'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Icon from '../../components/common/Icon'
import { WalletIcon } from '../../components/icons/WalletIcon'
import { WALLET_COLORS } from '../../utils/constants'
import { formatAddress, numberFormat } from '../../utils/function'
import TokenInfomation from './TokenInfomation'

// Assets
import accordionIcon from '../../assets/revamp/icons/accordion.svg'
import arrowDownIcon from '../../assets/revamp/icons/arrow_down.svg'
import arrowUpIcon from '../../assets/revamp/icons/arrow_up.svg'
import {
  ITokenAssetInfo,
  ITokenTimeSeries,
  useLazyFetchTokenInfoQuery,
  useLazyFetchTokenTimeSeriesQuery,
} from '../../services/modules/portfolio-manager'

interface WalletItemProps {
  asset: ITokenAssetInfo
  id: string | number
  openingTokenId: string | number
  changeOpeningTokenId: (id: string | number) => void
}

const WalletItem: React.FC<WalletItemProps> = props => {
  const { asset, id, changeOpeningTokenId, openingTokenId } = props
  const [searchParams, setSearchParams] = useSearchParams()
  let activeAddress = searchParams.get('active') || ''
  const [tokenDataChart, setTokenDataChart] = useState<ITokenTimeSeries | null>(
    null,
  )
  const isOpenChart = openingTokenId === id
  const [currentFilter, setFilter] = useState<string>('1d')

  const calculateAssetsChange = () => {
    if (!asset) return null

    const lastChange = parseFloat(asset.token.price_24h_change ?? '')
    const isIncrease = lastChange > 0
    const changePercentage =
      Math.abs(
        lastChange / (parseFloat(asset.token.latest_price ?? '') - lastChange),
      ) * 100

    return (
      <span>
        <Icon src={isIncrease ? arrowUpIcon : arrowDownIcon} />
        &nbsp;
        {
          <b>
            {changePercentage > 0.01
              ? numberFormat(changePercentage, 2, 2)
              : '<0.01'}
            %
          </b>
        }
      </span>
    )
  }

  const chartChangeRate = () => {
    if (!asset || !tokenDataChart) return null

    const { timeSeries } = tokenDataChart
    const timeseriesValues = Object.values(timeSeries || [])

    const firstValue = timeseriesValues[0]
    const lastValue = timeseriesValues[timeseriesValues.length - 1]
    const isIncrease = lastValue - firstValue > 0
    const changePercentage =
      Math.abs((lastValue - firstValue) / firstValue) * 100

    return (
      <span className="change">
        <Icon src={isIncrease ? arrowUpIcon : arrowDownIcon} />
        &nbsp;
        {
          <b>
            {changePercentage > 0.01
              ? numberFormat(changePercentage, 2, 2)
              : '<0.01'}
            %
          </b>
        }
      </span>
    )
  }

  const [fetchTokenInfo, { data: tokenInfoRes }] = useLazyFetchTokenInfoQuery()

  const [fetchTokenTimeSeries, { data: chartData }] =
    useLazyFetchTokenTimeSeriesQuery()

  const handleFetchTokenData = () => {
    fetchTokenInfo({
      tokenId: asset.token.id ?? '',
    })
    fetchTokenTimeSeries({
      tierName: currentFilter,
      tokenId: asset.token.id ?? '',
    })
  }

  // const fetchDetailTokenChart = async () => {
  //   setLoading(true)

  //   try {
  //     // let chartData = await portfolioService.getDetailTokenChart(
  //     //   asset.token.id,
  //     //   '1d',
  //     // )
  //     let chartData = sampleTokenDataChart
  //     setTokenDataChart(chartData)

  //     // let tokenInfo = await portfolioService.getTokenInfo(asset.token.id)
  //     // let tokenInfo = sampleTokenInfo
  //     // setTokenInfo(tokenInfo)
  //   } catch (error) {
  //     console.log('Error: ', error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const handleChangeChartTier = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setFilter(event.target.value)
  }

  const totalBalance = () => {
    // if (asset.references) {
    //   return (
    //     parseFloat(asset.balance || '0') +
    //     asset.references.reduce((total, current) => {
    //       total += parseFloat(current.balance || '0')
    //       return total
    //     }, 0)
    //   )
    // }

    return parseFloat(asset.balance || '0')
  }

  const totalUsdValue = () => {
    // if (asset.references) {
    //   return (
    //     parseFloat(asset.usd_value || '0') +
    //     asset.references.reduce((total, current) => {
    //       total += parseFloat(current.usd_value || '0')
    //       return total
    //     }, 0)
    //   )
    // }

    return parseFloat(asset.usd_value || '0')
  }

  return (
    <div className="wallet-overview--accordion">
      <input type="checkbox" id={`token-${id}`} />
      {/* Label */}
      <label
        htmlFor={`token-${id}`}
        onClick={() => {
          handleFetchTokenData()
        }}
      >
        <aside>
          <div>
            <img src={asset.token.image ?? ''} alt={asset.token.denom ?? ''} />
          </div>
          <span>
            <h3>{asset.token.name}</h3>
            <p>
              {/* {activeAddress === 'bundled' && !asset.references && ( */}
              {activeAddress === 'bundled' && (
                <Tooltip
                  title={formatAddress(asset?.wallet?.address)}
                  color="#600083"
                >
                  <span>
                    <WalletIcon fill={WALLET_COLORS[0]} />
                  </span>
                </Tooltip>
              )}
              {/* {asset.references ? (
                <>
                  <Tooltip
                    title={formatAddress(asset?.wallet?.address)}
                    color="#600083"
                  >
                    <span>
                      <WalletIcon fill={WALLET_COLORS[0]} />
                    </span>
                  </Tooltip>
                  {asset.references.map((assetItem, index) => (
                    <Tooltip
                      title={formatAddress(assetItem?.wallet?.address)}
                      color="#600083"
                      key={index}
                    >
                      <span>
                        <WalletIcon fill={WALLET_COLORS[index + 1]} />
                      </span>
                    </Tooltip>
                  ))}
                </>
              ) : (
                <></>
              )} */}
              {numberFormat(totalBalance(), 2, 2)} {asset.token.denom}
            </p>
          </span>
        </aside>
        <div>
          <span>
            <b>${numberFormat(totalUsdValue(), 2, 2)}</b>
          </span>
          <Icon src={accordionIcon} className="toggle" />
        </div>
      </label>
      {/* Content */}
      <div className="accordion-content">
        <div className="title">
          <b>Token Information</b>
          <aside>
            <b>Report</b>
            <b>Blacklist</b>
          </aside>
        </div>
        <div className="accordion-content-wrapper">
          {/* {asset.references && !isOpenChart ? ( */}
          {/* {!isOpenChart ? (
            <TokenHoldingInfo
              asset={asset}
              openChart={id => changeOpeningTokenId(id)}
            />
          ) : ( */}
          {asset ? (
            <TokenInfomation
              asset={asset}
              tokenDataChart={chartData ?? null}
              currentFilter={currentFilter}
              handleChangeChartTier={handleChangeChartTier}
              backToHolding={id => changeOpeningTokenId(id)}
            />
          ) : null}
          {/* )} */}
          <div className="description">
            <aside>
              <div>
                <img
                  src={asset.token.image ?? ''}
                  alt={asset.token.name ?? ''}
                />
              </div>
              <h4>{asset.token.name}</h4>
            </aside>
            <div>
              {tokenInfoRes && (
                <p>{tokenInfoRes.extended_information?.description}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WalletItem
