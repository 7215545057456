import {
  Box,
  TextField,
  Typography,
  Divider,
  styled,
  Checkbox,
  useMediaQuery,
  Tooltip,
} from '@mui/material'
import {
  FilledBlackArrow,
  InvestmentIcon,
  InvestmentPattern,
  MinusIcon,
  MoneyIcon,
  PlusIcon,
  TicketIcon,
} from '../../assets'
import { BoxUnion } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useEffect, useState } from 'react'
import {
  useAccount,
  useBalance,
  useReadContract,
  useReadContracts,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi'
import { CONTRACT_ADDRESS, MAIN_CHAIN_ID } from '../../constant'
import { AvailableContracts } from '../../hooks/useContract/types'
import ApeABI from '../../contracts/ape.json'
import {
  IProject,
  useFetchInvestMerkleProofQuery,
  useInvestInProjectMutation,
} from '../../services/modules/project'
import { useUpdateParticipationMutation } from '../../services/modules/participations'
import { ethers } from 'ethers'
import { erc20Abi } from 'viem'
import { Check, Close } from '@mui/icons-material'
import { PROJECT_TYPE_DICTIONARY } from '../../constants/application'
import { useAnalytics } from '../../AmplitudeContext'
import toast from 'react-hot-toast'
import { getExceptionMessage } from '../../utils/index'

const USDC_ADDRESS :`0x${string}` = CONTRACT_ADDRESS.USDC[MAIN_CHAIN_ID] as `0x${string}`;

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  color: '#767676',
  fontWeight: 400,
  lineHeight: '22.5px',
  fontFamily: 'Inter',
  textAlign: 'left',
  width: '100%',
  whiteSpace: 'nowrap',
}))

const StyledSubText = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  color: '#FFFFFF',
  fontWeight: 600,
  lineHeight: '31px',
  fontFamily: 'Hellix',
  textAlign: 'left',
  width: '100%',
  display: 'flex',
  lineBreak: 'anywhere',
}))

const InvestmentBox = ({
  idoData,
  onInvested,
  project,
}: {
  project: IProject
  idoData: any
  onInvested: any
}) => {
  const [value, setValue] = useState(1)
  const [isChecked, setIsChecked] = useState(false)

  const [investInProject, { data, isSuccess, error, isError }] =
    useInvestInProjectMutation()
  const [userBalance, setUserBalance] = useState('0')

  const {
    data: merkleProofData,
    isSuccess: isMerkleProofSuccess,
    error: merkleProofError,
    isError: isMerkleProofError,
  } = useFetchInvestMerkleProofQuery({
    projectId: idoData.project._id,
    idoNumber: 0,
  })

  const [isLoading, setIsLoading] = useState(false)

  const [
    updateParticipation,
    { data: participationData, isSuccess: isParticipationSuccess },
  ] = useUpdateParticipationMutation()

  const isDown1152 = useMediaQuery('(max-width:1152px)')

  const isDown1000 = useMediaQuery('(max-width:1000px)')

  const [investmentAmount, setInvestmentAmount] = useState('')

  const [applyCredit, setApplyCredit] = useState(false)

  const [invested, setInvested] = useState(false)

  const [needMoreAllowance, setNeedMoreAllowance] = useState(false)

  const [paymentOption, setPaymentOption] = useState(0)

  const { address } = useAccount()

  const [stage, setStage] = useState('allowance')

  const isDown768 = useMediaQuery('(max-width:768px)')

  const [tokenAllowanceValue, setTokenAllowanceValue] = useState(0)

  const [showTooltip, setShowTooltip] = useState(false)

  const { trackEvent } = useAnalytics()

  const userToken = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: 'decimals',
      },
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: 'name',
      },
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: 'symbol',
      },
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: 'totalSupply',
      },
    ],
  })

  const balance = useBalance({
    // address: USDC_ADDRESS,
    address: address,
    token: USDC_ADDRESS,
  })

  const {
    data: tokenInAllowanceData,
    isSuccess: isTokenInAllowanceSuccess,
    error: tokenInAllowanceError,
    isError: isTokenInAllowanceError,
    refetch: fetchAllowance,
  } = useReadContract({
    address: USDC_ADDRESS,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [address!, AvailableContracts.APE_CONTRACT],
  })
  const {
    writeContractAsync: approve,
    data: tokenApproveData,
    isError: isTokenApproveError,
    isPending: isTokenApproveLoading,
    // isLoading: isTokenApproveLoading,
    isSuccess: isTokenApproveSuccess,
  } = useWriteContract()

  const {
    data: approveTxReceipt,
    isError: isErrorApproveTx,
    isLoading: isLoadingApproveTx,
    isSuccess: isSuccessApproveTx,
  } = useWaitForTransactionReceipt({
    hash: tokenApproveData,
    confirmations: 1,
  })

  const {
    writeContractAsync: purchaseIDO,
    data: purchaseIDOData,
    isError: isErrorPurchaseIDO,
    isPending: isLoadingPurchaseIDO,
    isSuccess: isSuccessPurchaseIDO,
  } = useWriteContract()

  const {
    data: purchaseIDOReceipt,
    isError: isErrorPurchaseIDOReceipt,
    isLoading: isLoadingPurchaseIDOReceipt,
    isSuccess: isSuccessPurchaseIDOReceipt,
  } = useWaitForTransactionReceipt({
    hash: purchaseIDOData,
    confirmations: 1,
  })

  useEffect(() => {
    try {
      let _tokenAllowanceFormatted = Number(
        ethers.utils.formatUnits(tokenInAllowanceData!.toString(), 18),
      )
      setTokenAllowanceValue(_tokenAllowanceFormatted)
      if (
        Number(_tokenAllowanceFormatted) <
        Number(idoData.amountToRaise / idoData.totalWinners)
      ) {
        setNeedMoreAllowance(true)
      } else {
        setNeedMoreAllowance(false)
      }
    } catch (error) {
      console.error('allowance error')
    }
  }, [tokenInAllowanceData])

  useEffect(() => {
    if (userToken) {
      setUserBalance(balance.data?.formatted || '0')
    }
  }, [userToken])

  useEffect(() => {
    if (approveTxReceipt) {
      console.log('approveTxReceipt', approveTxReceipt)

      setNeedMoreAllowance(false)
      setIsLoading(false)
    }
  }, [approveTxReceipt])

  useEffect(() => {
    if (purchaseIDOReceipt) {
      console.log('purchaseIDOReceipt', purchaseIDOReceipt)
      updateParticipation({
        idoId: idoData._id,
        invested: true,
      })
      setInvested(true)
      setIsLoading(false)
      onInvested()
      trackEvent("ProjectInvestmentCompleted", {
        project_type: idoData?.amountToRaise && idoData?.amountToRaise > 0 ? PROJECT_TYPE_DICTIONARY.IDO : PROJECT_TYPE_DICTIONARY.AIRDROP,
        project_name: String(project?.name).toLowerCase().replace(" ", "_"),
        wallet_size: Number.parseFloat(ethBalance) ? Number.parseFloat(ethBalance).toString() : 0,
        number_of_tickets: value,
        ticket_price: idoData.amountToRaise / idoData.totalInvestors
      })
    }
  }, [purchaseIDOReceipt])

  // useEffect(() => {
  //   checkIfAllowanceNeeded()
  // }, [tokenInAllowance])

  // const checkIfAllowanceNeeded = () => {
  //   console.log('checkIfAllowanceNeeded')
  //   if (tokenInAllowance) {
  //     console.log(
  //       'checkIfAllowanceNeeded',
  //       tokenInAllowance.toFixed(),
  //       '===>',
  //       Number(ethers.utils.formatUnits(tokenInAllowance!.toFixed(), 18)),
  //     )
  //   }
  //   if (
  //     (tokenInAllowance || tokenInAllowance === 0) &&
  //     Number(ethers.utils.formatUnits(tokenInAllowance!.toFixed(), 18)) <
  //       idoData.amountToRaise / idoData.totalWinners
  //   ) {
  //     setNeedMoreAllowance(true)
  //   } else {
  //     setNeedMoreAllowance(false)
  //   }
  // }

  useEffect(() => {
    if (isParticipationSuccess) {
      setInvested(true)
    }
  }, [participationData, isParticipationSuccess])

  const { data: ethBalanceData } = useBalance({
    address: address,
  })
  const ethBalance = ethBalanceData ? ethBalanceData.formatted : '0';

  const onInvest = async () => {
    try {
      setIsLoading(true)
      if (isMerkleProofSuccess && merkleProofData) {
        purchaseIDO({
          abi: ApeABI,
          address: AvailableContracts.APE_CONTRACT,
          functionName: 'purchaseIDO',
          args: [
            {
              id: idoData.project.contractId,
              amount: ethers.utils
                .parseUnits(
                  (idoData.amountToRaise / idoData.totalWinners).toFixed(6),
                  18,
                )
                .toString(),
              maxInvestment: ethers.utils
                .parseUnits(
                  (idoData.amountToRaise / idoData.totalWinners).toFixed(6),
                  18,
                )
                .toString(),

              _paymentOption: 0,
            },
            merkleProofData,
          ],
        })
      }
    } catch (error) {
      console.error('onInvest error', error)
      toast.error(getExceptionMessage(error));
    } finally {
      setIsLoading(false)
    }
  }

  const onApprove = async (amount: number) => {
    let _approvalRequired = ethers.utils
      .parseUnits(
        Math.ceil(idoData.amountToRaise / idoData.totalWinners).toString(),
        18,
      )
      .toString()

    try {
      setIsLoading(true)
      await approve({
        abi: erc20Abi,
        address: USDC_ADDRESS,
        functionName: 'approve',
        args: [
          AvailableContracts.APE_CONTRACT,
          '10000000000000000000000000000000' as any,
        ],
      })
      
      
    } catch (error) {
      console.error('onApprove error', error)
      setIsLoading(false)
    }
  }

  const handleChange = (event: any) => {
    const newValue = parseInt(event.target.value, 10)
    if (newValue >= 0) {
      setValue(newValue)
    }
  }

  const increment = () => {
    setValue(prevValue => prevValue + 1)
  }

  const decrement = () => {
    if (value === 0) return
    setValue(prevValue => prevValue - 1)
  }

  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        width={'100%'}
        padding={isDown1000 ? '24px' : '32px'}
      >
        <img
          src={isDown1000 ? InvestmentPattern : InvestmentIcon}
          alt="keep trying"
          style={{
            width: isDown1000 ? '208px' : '64px',
            height: isDown1000 ? '208px' : '64px',
          }}
        />
        <Typography
          pt={isDown1000 ? 0 : '32px'}
          marginTop={isDown1000 ? '-45px' : '0px'}
          pb={isDown1000 ? 0 : '16px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#FFFFFF'}
          paddingX={isDown1000 ? '24px' : '22%'}
        >
          Investment
        </Typography>
        <Box
          sx={{
            backgroundColor: '#0F1113',
            width: '100%',
            paddingX: '16px',
            paddingBottom: '16px',
            mt: '26px',
            borderRadius: '8px',
          }}
        >
          <img
            src={BoxUnion}
            alt="box-union"
            style={{
              position: 'relative',
              top: '-2px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
              gap: '10px',
            }}
          >
            <Box
              gap={isDown1000 ? '0px' : '2px'}
              display={'flex'}
              flexDirection={isDown1000 ? 'row' : 'column'}
              width={'100%'}
              alignItems={'center'}
            >
              <img
                src={TicketIcon}
                alt="ticket"
                width={'24px'}
                height={'24px'}
                style={{
                  marginRight: '8px',
                }}
              />
              <StyledText>Amount:</StyledText>
              <StyledSubText
                sx={{
                  color: '#FF7722',
                  justifyContent: isDown1000 ? 'right' : 'left',
                }}
              >
                1 ticket
              </StyledSubText>
            </Box>
            <Box
              gap={'2px'}
              display={'flex'}
              flexDirection={isDown1000 ? 'row' : 'column'}
              width={'100%'}
              alignItems={'center'}
            >
              <img
                src={MoneyIcon}
                alt="money"
                width={'24px'}
                height={'24px'}
                style={{
                  marginRight: '8px',
                }}
              />
              <StyledText>Each Ticket price:</StyledText>
              <StyledSubText
                sx={{
                  justifyContent: isDown1000 ? 'right' : 'left',
                }}
              >
                {idoData.amountToRaise / idoData.totalWinners} USDC
              </StyledSubText>
            </Box>
          </Box>
          <Divider
            sx={{
              height: '0.5px',
              my: '12px',
              borderColor: '#1D1F21',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              width={'100%'}
              justifyContent={isDown1000 ? 'center' : 'flex-start'}
            >
              <Box
                component="img"
                src={MinusIcon}
                alt="minus"
                //  onClick={decrement}
                sx={{
                  cursor: 'pointer',
                  padding: '14px',
                  '&:hover': {
                    opacity: 0.9,
                  },
                }}
              />
              <TextField
                id="quantity"
                value={value}
                onChange={handleChange}
                disabled
                type="number"
                InputProps={{
                  inputProps: { min: 1 },
                }}
                sx={{
                  maxWidth: isDown1000 ? '96px' : '80px',
                  width: '100%',
                  minWidth: isDown1000 ? '96px' : '40px',
                  flexShrink: 1,
                  height: '48px',
                  maxHeight: '48px',
                  borderRadius: '4px',
                  backgroundColor: '#1D1F21',
                  border: 'none',
                  '.MuiInputBase-root': {
                    height: '48px',
                    padding: 0,
                  },
                  '.MuiOutlinedInput-input': {
                    textAlign: 'center',
                    maxHeight: '48px',
                    padding: 0,
                    fontSize: '18px',
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    '&:disabled': {
                      color: '#EBEBE4', 
                      WebkitTextFillColor: '#EBEBE4',
                    },
                  },
                  'MuiOutlinedInput-input:disabled': {
                    color: '#EBEBE4',
                    WebkitTextFillColor: '#EBEBE4',
                  },

                  '&:hover': {
                    border: 'none',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    maxHeight: '48px',
                  },
                }}
              />
              <Box
                component="img"
                src={PlusIcon}
                alt="plus"
                //   onClick={increment}
                sx={{
                  cursor: 'pointer',
                  padding: '14px',
                  '&:hover': {
                    opacity: 0.9,
                  },
                }}
              />
            </Box>
            <Divider
              sx={{
                height: '0.5px',
                my: '12px',
                borderColor: '#1D1F21',
                display: isDown1000 ? 'block' : 'none',
              }}
            />
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              width={'100%'}
              justifyContent={'flex-start'}
              borderLeft={isDown1000 ? 'none' : '1px solid #1d1f21'}
              padding="10px"
            >
              <Tooltip
                title="Coming soon"
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
              >
                <Checkbox
                  // {...{ isChecked }}
                  // onChange={() => setIsChecked(!isChecked)}
                  //defaultChecked
                  checked={false}
                  disableRipple
                  color="default"
                  // disabled={true}
                  sx={{
                    color: '#000000',
                    borderRadius: '0px',
                    height: '22px',
                    width: '22px',
                    backgroundColor: '#63EA71',
                    padding: '0',
                    // paddingLeft: isDown1000 ? '0px' : '16px',
                    // paddingRight: '16px',
                    // '&.Mui-checked': {
                    //   color: '#63EA71',
                    // },
                  }}
                  onClick={() => setShowTooltip(!showTooltip)}
                  icon={
                    <Close
                      sx={{
                        color: '#000000',
                        height: '16px',
                        width: '16px',
                      }}
                    />
                  } // Cross icon when unchecked
                  checkedIcon={
                    <Check
                      sx={{ color: '#000000', height: '16px', width: '16px' }}
                    />
                  } // Tick icon when checked
                />
              </Tooltip>
              <StyledText
                sx={{
                  fontSize: '16px',
                  color: '#ffffff',
                  marginLeft: '10px',
                }}
              >
                Apply $25 credit
              </StyledText>
            </Box>
          </Box>
        </Box>
        <GreenButton
          fs={15}
          lh="22.5px"
          fm="Inter"
          fw={400}
          br="24px"
          text={
            isLoading
              ? 'Loading...'
              : Number(userBalance) <
                Number(idoData.amountToRaise / idoData?.totalWinners)
              ? 'Insufficient Balance'
              : needMoreAllowance
              ? 'Approve Transaction'
              : isSuccessPurchaseIDO ?  'Invested'  : 'Invest'
          }
          mt={5}
          disabled={
            isLoading || isSuccessPurchaseIDO || isLoadingPurchaseIDO ||
            Number(userBalance) <
              Number(idoData.amountToRaise / idoData?.totalWinners)
          }
          onClick={() => {
            if (
              Number(userBalance) <
              Number(idoData.amountToRaise / idoData?.totalWinners)
            ) {
              return
            } else if (needMoreAllowance) {
              onApprove(Number(investmentAmount))
            } else {
              onInvest()
            }
          }}
          w="242px"
          IconStart={() => <img src={FilledBlackArrow} alt="arrow" />}
        />
      </Box>
    </Box>
  )
}

export default InvestmentBox
