import { formatUnixToDate } from '../../constants/formatDate'
import { NextUnlockIcon } from '../../assets'
import { useFetchUserPortfolioStatsQuery } from '../../services/modules/participations'
import { useAppSelector } from '../../hooks'
import { useAccount } from 'wagmi'

const OverviewUserPortal = () => {
  const mainAppToken = useAppSelector(state => state.user.mainAppToken)
  const { address } = useAccount()
  const { data: portfolioStats } = useFetchUserPortfolioStatsQuery(undefined, {
    skip: !address && !mainAppToken,
    refetchOnFocus: true,
  })

  return (
    <section className="user-portal-header">
      <div className="user-portal-header-wrapper">
        <aside className="values">
          <span className="values--item">
            <span>Amount Invested</span>
            <b>
              {portfolioStats && portfolioStats?.amountInvested > 0 
                ? `$${portfolioStats?.amountInvested.toLocaleString('en')}`
                : '$0,000.00'}
            </b>
          </span>
          <span className="values--item">
            <span>
              <img src={NextUnlockIcon} alt="NextUnlockIcon" />
              Next Unlock
            </span>
            <b>
              {portfolioStats &&
              portfolioStats?.nextUnlock &&
              portfolioStats?.nextUnlock !== 'TBD'
                ? formatUnixToDate(portfolioStats?.nextUnlock)
                : 'TBD'}
            </b>
          </span>
        </aside>
        <aside className="values">
          <span className="values--item">
            <span>Invested Projects</span>
            <b>{portfolioStats?.investmentGain || 0}</b>
          </span>
          <span className="values--item">
            <span>Investment Gain</span>
            <b>
              {portfolioStats && portfolioStats?.investmentGain
                ? `$${portfolioStats?.investmentGain.toLocaleString('en')}`
                : '$0,000.00'}
            </b>
          </span>
          <span className="values--item">
            <span>Annualized ROI</span>
            <b>
              {portfolioStats && portfolioStats?.annualizedROI !== null
                ? portfolioStats?.annualizedROI?.toFixed(2)
                : '0.00'}
            </b>
          </span>
        </aside>
      </div>
    </section>
  )
}
export default OverviewUserPortal
