import { Box } from '@mui/material'
import OverviewUserPortal from './OverviewUserPortal'
import UserTransactions from './user-transactions'

const UserPortal = () => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <OverviewUserPortal />
      <UserTransactions />
    </Box>
  )
}
export default UserPortal
