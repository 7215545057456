//@ts-nocheck
import { Modal } from "antd";
import ConnectNetworkBox from "./ConnectNetworkBox";
import LoadingBtn from "../../common/LoadingBtn";
import CancelIcon from "../../../anyfi-src/assets/icons/cancel-icon.svg";

const ModalSwitchNetwork = (props) => {
  const {
    opened,
    handleClose,
    selectedNetwork,
    handleSwitchNetwork,
    handleNetworkClick,
    isLoadingConnect,
  } = props;

  return (
    <Modal
      title={null}
      open={opened}
      footer={null}
      centered
      maskClosable={false}
      onCancel={handleClose}
      closeIcon={<img src={CancelIcon} alt="cancelIcon" />}
      className="modal-switch"
    >
      <div className="modal-connect-wallet">
        <h1>Switch Network</h1>
        <div className="box-network">
          <ConnectNetworkBox
            selectedNetwork={selectedNetwork}
            handleNetworkClick={handleNetworkClick}
          />
        </div>
        <button
          onClick={handleSwitchNetwork}
          disabled={!selectedNetwork || isLoadingConnect}
          className={`button register connect-button ${
            !selectedNetwork || isLoadingConnect ? "disabled not-allowed" : ""
          }`}
        >
          {isLoadingConnect ? <LoadingBtn /> : "Connect"}
        </button>
      </div>
    </Modal>
  );
};

export default ModalSwitchNetwork;
