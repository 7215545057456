//@ts-nocheck
import { Modal } from "antd";
import { useWallet } from "@solana/wallet-adapter-react";
import ConnectWalletBox from "./ConnectWalletBox";
import ConnectNetworkBox from "./ConnectNetworkBox";
import LoadingBtn from "../../common/LoadingBtn";
import {
  METAMASK_DEEPLINK,
  PHANTOM_DEEPLINK,
  NETWORKS_NAME,
} from "../../../utils/network";
import CancelIcon from "../../../anyfi-src/assets/icons/cancel-icon.svg";

const ConnectWalletModal = (props) => {
  const {
    opened,
    handleClose,
    selectedNetwork,
    handleNetworkClick,
    selectedConnector,
    handleConnectorClick,
    sortedConnectors,
    handleConnectWallet,
    isLoadingConnect,
  } = props;
  const { wallets } = useWallet();
  return (
    <Modal
      title={null}
      open={opened}
      footer={null}
      centered
      onCancel={handleClose}
      maskClosable={false}
      closeIcon={<img src={CancelIcon} alt="cancelIcon" />}
      className="modal-appove"
    >
      <div className="modal-connect-wallet">
        <h1>Connect a wallet</h1>
        <p>To continue you must connect a wallet.</p>
        <div className="box-network">
          <ConnectNetworkBox
            selectedNetwork={selectedNetwork}
            handleNetworkClick={handleNetworkClick}
          />
          <div className="box-connector-name">
            <ConnectWalletBox
              wallets={wallets}
              selectedNetwork={selectedNetwork}
              selectedConnector={selectedConnector}
              handleConnectorClick={handleConnectorClick}
              sortedConnectors={sortedConnectors}
            />
          </div>
        </div>
        {!window?.ethereum?.isMetaMask && (
          <p className="metamask">
            Please make sure your wallet is unlocked and available. If you do
            not currently have Web3 wallet, we suggest{" "}
            <a className="link" target="_blank" href={METAMASK_DEEPLINK}>
              Metamask
            </a>
          </p>
        )}
        {selectedNetwork === NETWORKS_NAME.SOLANA &&
          !window?.solana?.isPhantom && (
            <p className="metamask">
              No wallet found. Please download a supported Solana wallet{" "}
              <a className="link" target="_blank" href={PHANTOM_DEEPLINK}>
                Phantom
              </a>
            </p>
          )}
        <div className="connect-wallet-modal--container-footer">
          <p>
            By clicking sign in you indicate that you have read and agree to our{" "}
            <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.
          </p>
        </div>
        <button
          onClick={() => handleConnectWallet(selectedConnector)}
          disabled={!selectedConnector || !selectedNetwork || isLoadingConnect}
          className={`button register connect-button ${
            !selectedConnector || !selectedNetwork || isLoadingConnect
              ? "disabled not-allowed"
              : ""
          }`}
        >
          {isLoadingConnect ? <LoadingBtn /> : <span>Connect</span>}
        </button>
      </div>
    </Modal>
  );
};

export default ConnectWalletModal;
