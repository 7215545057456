import { Circle } from 'rc-progress'
import {
  FixedBodyTableCell,
  FixedHeadTableCell,
  StyledBodyTableCell,
  StyledHeadTableCell,
} from './MySalesTable'
import { Box, useMediaQuery } from '@mui/system'
import BigNumber from 'bignumber.js'
import { formatRoundDown } from '../../../utils/function'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  formatBigNumber,
  getReplacedCdnEndpoint,
  replaceUnderscoresWithSpaces,
} from '../../../utils'
import { getStatusStyleFilter } from './FilterStatusMySales'
import { formatUnixToDate } from '../../../constants/formatDate'
import OutlinedButton from '../../../components/Buttons/OutlinedButton'
import {
  IResponseUserPortalItem,
  IResponseUserPortalMeta,
} from '../../../services/modules/participationsV2'
import { getNetworkIconByChain } from './IconByNetworkUserPortal'
import { useNavigate } from 'react-router-dom'
import { useClaimData } from '../../../hooks/useClaimData'
// import ButtonClaim from './ButtonClaim'

const ClaimRow = ({ sale }: { sale: IResponseUserPortalItem }) => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  const navigate = useNavigate()
  const netWork = sale?.idoClaimNetwork || sale?.tokenNetwork
  const { claimData, dataProject } = useClaimData({ sale })

  return (
    <TableRow
      key={sale.id}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#000000',
        },
      }}
      onClick={() => navigate(`/project/${sale?.projectName}`)}
    >
      {isDown800 ? (
        <FixedBodyTableCell>
          <img
            src={getReplacedCdnEndpoint(sale?.projectLogoUrl)}
            alt={sale.projectName}
            style={{ width: 40, height: 40, borderRadius: 10 }}
          />
        </FixedBodyTableCell>
      ) : (
        <StyledBodyTableCell>
          <Box display="flex" alignItems="center">
            <img
              src={sale.projectLogoUrl}
              alt={sale.projectName}
              style={{ width: 40, height: 40, borderRadius: 10 }}
            />
            <Box ml={'12px'}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                }}
              >
                {replaceUnderscoresWithSpaces(sale.projectName)}
              </Typography>
              <Typography
                sx={{
                  color: '#7E8180',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {sale.tokenSymbol}
              </Typography>
            </Box>
          </Box>
        </StyledBodyTableCell>
      )}
      <StyledBodyTableCell>
        {Number(sale.nextUnlock) ? formatUnixToDate(sale.nextUnlock) : 'TBD'}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        <img src={getNetworkIconByChain(netWork)} alt={netWork} />
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '88px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',

            ...getStatusStyleFilter(sale.idoStatus),
          }}
        >
          {sale.idoStatus}
        </Box>
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {sale.allocation ? Number(sale.allocation).toLocaleString('en') : '0'}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        <div className="received">
          <span className="graph">
            <Circle
              percent={sale.distribution}
              strokeWidth={3}
              strokeColor="#78D372"
            />
            {!sale.distribution ? (
              <b>0</b>
            ) : BigNumber(sale.distribution).gte(100) ? (
              <b>100%</b>
            ) : (
              <b>{formatRoundDown(sale.distribution, 0)}%</b>
            )}
          </span>
        </div>
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {formatBigNumber(claimData?.[3], dataProject?.[13])} {sale.tokenSymbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {formatBigNumber(claimData?.[2], dataProject?.[13])} {sale.tokenSymbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '69px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',
            border: '1px solid #313335',
          }}
          onClick={e => {
            e.stopPropagation()
            navigate(`/project/${sale.projectName}?claim=true`)
          }}
        >
          Claim
        </Box>
      </StyledBodyTableCell>
    </TableRow>
  )
}

const ClaimTable = ({
  claimSales,
  handleShowMore,
  meta,
  isShowButton,
}: {
  isShowButton: boolean
  meta: IResponseUserPortalMeta | undefined
  claimSales: IResponseUserPortalItem[]
  handleShowMore: React.MouseEventHandler<HTMLButtonElement>
}) => {
  const isDown800 = useMediaQuery('(max-width:800px)')

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'56px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minHeight: claimSales.length <= 5 ? '100%' : '408px',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  textWrap: 'nowrap',
                }}
              >
                {isDown800 ? (
                  <FixedHeadTableCell>Projects</FixedHeadTableCell>
                ) : (
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                )}
                <StyledHeadTableCell>Next Unlock</StyledHeadTableCell>
                <StyledHeadTableCell>Chain</StyledHeadTableCell>
                <StyledHeadTableCell>Status</StyledHeadTableCell>
                <StyledHeadTableCell>Allocation</StyledHeadTableCell>
                <StyledHeadTableCell>Distribution</StyledHeadTableCell>
                <StyledHeadTableCell>Claimable</StyledHeadTableCell>
                <StyledHeadTableCell>Claimed</StyledHeadTableCell>
                <StyledHeadTableCell>Action</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {claimSales.map((sale: IResponseUserPortalItem) => (
                <ClaimRow sale={sale} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {!isShowButton && Number(meta?.total_records) > 10 && (
        <Box mt={2} textAlign="center">
          <OutlinedButton
            onClick={handleShowMore}
            sx={{ height: '48px', width: '100%' }}
          >
            {Number(meta?.total_records) < claimSales.length
              ? 'Show More'
              : 'Show Less'}
          </OutlinedButton>
        </Box>
      )}
    </Box>
  )
}

export default ClaimTable
