import { api } from '../../../services/api'

export enum IFilterStatus {
  ALL = 'All',
  PARTICIPATION = 'Participation',
  INVESTMENT = 'Investment',
  TGE = 'TGE',
  COMPLETE = 'Complete',
}

export interface IResponseUserPortalItem {
  id: string
  nextUnlock: number
  projectName: string
  projectLogoUrl: string
  routeName: string
  idoStatus: string
  allocation: number
  distribution: number
  valueLocked: number
  tokenSymbol: string
  idoTotalParticipation: number
  idoTotalAssetsConnected: number
  amount: number
  invested: boolean
  idoClaimNetwork: string
  tokenNetwork: string
  investmentDate: string
  isWinner: boolean
  idoClaimContract: string
  projectClaimId: number
  projectVestingId: number
  projectContractId: number
}

export interface IResponseUserPortalMeta {
  page_size: number
  total_page: number
  total_records: number
}

export interface IResponseUserPortal {
  data: IResponseUserPortalItem[]
  meta: IResponseUserPortalMeta
}

export const participationApi = api.injectEndpoints({
  endpoints: build => ({
    fetchUserParticipationsV2: build.query<
      IResponseUserPortal,
      {
        page: number
        limit: number
        name?: string
        status?: string
      }
    >({
      query: ({ page, limit, name, status }) => {
        const params = new URLSearchParams()
        params.append('page', page.toString())
        params.append('limit', limit.toString())

        if (name) params.append('name', name)
        if (status) params.append('status', status)

        return `participation/user/all/v2?${params.toString()}`
      },
    }),
  }),
  overrideExisting: false,
})

export const { useFetchUserParticipationsV2Query } = participationApi
