import { Box, useMediaQuery } from '@mui/material'
import BoxShowDataProfile from './BoxShowDataProfile'
import KYCnSocialBox from './KYCnSocialBox'
import {
  PortfolioGreenKYC,
  PortfolioOrangeKYC,
  PortfolioRedKYC,
  PortfolioSocials,
} from '../../assets'
import { useAppSelector } from '../../hooks'
import { useAccount } from 'wagmi'
import { useEffect, useState } from 'react'
import KYCButton from '../../components/KYCBox/kycButton'
import SubscribeSubscription from './SubscribeSubscription'
import { useNavigate } from "react-router-dom"
import { PATHS } from "../../constants/config"
import { ETabsName } from "../../pages/UserPortal/user-transactions"

enum EStatusKyc {
  NOT_LOGGED = 'not-logged',
  REJECTED = 'rejected',
  UNVERIFIED = 'unverified',
  IN_REVIEW = 'inreview',
  APPROVED = 'approved',
}

const getStatusTextDisplayKyc = (status: string) => {
  switch (status) {
    case EStatusKyc.APPROVED:
      return {
        kycStatus: EStatusKyc.APPROVED,
        title: 'Your identity has been confirmed.',
        buttonText: 'Approved',
        icon: PortfolioOrangeKYC,
        color: 'green',
      }
    case EStatusKyc.IN_REVIEW:
      return {
        kycStatus: EStatusKyc.IN_REVIEW,
        title: 'Your identity is being reviewed.',
        buttonText: 'In Review',
        icon: PortfolioOrangeKYC,
        color: 'orange',
      }

    case EStatusKyc.REJECTED:
      return {
        kycStatus: EStatusKyc.REJECTED,
        title: 'Your identity has been rejected.',
        buttonText: 'Complete KYC',
        icon: PortfolioRedKYC,
        color: 'red',
      }
    case EStatusKyc.UNVERIFIED:
      return {
        kycStatus: EStatusKyc.UNVERIFIED,
        title: 'Complete a simple KYC process.',
        buttonText: 'Complete KYC',
        icon: PortfolioGreenKYC,
        color: 'green',
      }
    default:
      return {
        kycStatus: EStatusKyc.NOT_LOGGED,
        title: 'Complete a simple KYC process.',
        buttonText: 'Complete KYC',
        icon: PortfolioGreenKYC,
        color: 'green',
        helpText: 'Connect wallet to proceed',
      }
  }
}

const AccountInfo = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown770 = useMediaQuery('(max-width:770px)')
  const user = useAppSelector(state => state.user)
  const { address } = useAccount()
  const navigate = useNavigate()
  const [kYCStatus, setKYCStatus] = useState(EStatusKyc.NOT_LOGGED)
  const [isKYCVerified, setIsKYCVerified] = useState(false)

  const handleReferralsClick = () => {
    navigate(PATHS.userPortal, { state: { activeTab: ETabsName.Referral } });
  };

  useEffect(() => {
    if (user.kycStatus && address) {
      setKYCStatus(user.kycStatus as EStatusKyc)
    }
    if (!address) {
      setKYCStatus(EStatusKyc.NOT_LOGGED)
    }
  }, [user.kycStatus, address])

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: '20px',
      }}
    >
      <Box
        width={'100%'}
        alignSelf={'center'}
        display={'flex'}
        flexDirection={'column'}
        paddingTop={'80px'}
      >
        <Box
          display={'flex'}
          flexDirection={isDown1000 ? 'column-reverse' : 'column'}
          gap={'8px'}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: isDown770 ? 'column' : 'row',
              gap: '8px',
            }}
          >
            <BoxShowDataProfile
              type="wallet"
              heading={address! ? 'User Wallet Address' : ''}
              isShowButton={!address!}
              subheading={address! ? address! : ''}
            />

            <BoxShowDataProfile
              type="email"
              heading="User Email"
              subheading={
                !user.email && !user.emailRegisterUserAccount
                  ? 'No data available'
                  : user.email || user.emailRegisterUserAccount
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: isDown770 ? 'column' : 'row',
              gap: '8px',
            }}
          >
            <KYCnSocialBox
              image={getStatusTextDisplayKyc(kYCStatus).icon}
              heading="KYC Verification"
              subheading={getStatusTextDisplayKyc(kYCStatus).title}
              buttonText={getStatusTextDisplayKyc(kYCStatus).buttonText}
              buttonAction={() => {
                setIsKYCVerified(!isKYCVerified)
              }}
              status={getStatusTextDisplayKyc(kYCStatus).kycStatus}
              color={getStatusTextDisplayKyc(kYCStatus).color}
              helpText={getStatusTextDisplayKyc(kYCStatus).helpText}
              KYCButton={
                ['approved', 'inreview', 'not-logged'].includes(
                  getStatusTextDisplayKyc(kYCStatus).kycStatus,
                )
                  ? null
                  : KYCButton
              }
            />
            <KYCnSocialBox
              image={PortfolioSocials}
              heading="Social Connections"
              subheading="Increase your chances of winning."
              buttonText="Referrals"
              buttonAction={() => {
                handleReferralsClick()
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%', marginTop:'24px' }}>
        <SubscribeSubscription />
      </Box>
    </Box>
  )
}
export default AccountInfo
