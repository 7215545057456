import Chart from 'react-apexcharts'
import moment from 'moment'
import { numberFormat } from '../../utils/function'

interface TotalAssetsChartProps {
  data: {
    timeSeries: Record<string, number>
  }
  height?: number
  xAxisFormat?: string
}

const TotalAssetsChart: React.FC<TotalAssetsChartProps> = props => {
  const { data, height, xAxisFormat = 'hh:mm' } = props

  const timeSeriesData = data?.timeSeries ?? {}

  const dataTimeseries = Object.values(timeSeriesData)
  const dataTimeLabels = Object.keys(timeSeriesData)

  const series = [
    {
      data: dataTimeseries,
    },
  ]

  const options = {
    chart: {
      height: height ? height : 350,
      type: 'area',
      toolbar: { show: false },
      events: {},
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    grid: {
      show: false,
      padding: {
        left: -15,
        right: -20,
      },
    },
    colors: ['#6EF49F'],
    fill: {
      color: '#6EF49F',
      type: 'gradient',
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      min: 0,
      show: false,
    },
    xaxis: {
      categories: dataTimeLabels?.map(i =>
        moment(parseInt(i) * 1000).format(xAxisFormat),
      ),
      labels: {
        style: {
          colors: '#63ea71',
          fontSize: '10px',
          cssClass: 'overview-chart-label',
        },
        rotate: 0,
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      crosshairs: {
        show: true,
        opacity: 1,
        position: 'front',
        color: '#6EF49F',
      },
      tickPlacement: 'between',
      tickAmount: 12,
      tickInterval: '2 hour',
    },
    markers: {
      colors: '#6EF49F',
      hover: {
        sizeOffset: 3,
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
      y: {
        formatter: function (val: number) {
          return val
        },
      },
      custom: function ({
        series,
        seriesIndex,
        dataPointIndex,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
      }) {
        return `<div class="apexcharts-assets-tooltip">
          <p>$${numberFormat(series[seriesIndex][dataPointIndex], 2, 2)}</p>
          <p>${moment(parseInt(dataTimeLabels[dataPointIndex]) * 1000).format(
            'D MMM hh:mm A',
          )}</p>
        </div>`
      },
    },
  }

  return (
    <Chart
      options={options as any}
      series={series}
      type="area"
      height={height || 197}
    />
  )
}

export default TotalAssetsChart
