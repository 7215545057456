import { message } from 'antd'
import { cloneDeep } from 'lodash'
import { useMemo, useState } from 'react'
import Loading from '../../components/common/Loading'
import { useAppSelector } from '../../hooks'
import {
  ETierRange,
  useFetchUserAssetsQuery,
  useFetchUserAssetsTimeSeriesQuery,
} from '../../services/modules/portfolio-manager'
import { PORTFOLIO_NETWORKS, STORAGE_NETWORK_KEY } from '../../utils/constants'
import StorageService from '../../utils/storage'
import AssetsOverview from './AssetsOverview'
import NetworksModal from './NetWorksModal'
import PageHeader from './PageHeader'
import WalletOverview from './WalletOverview'

// const sampleUserStakingAssets: IAssetInfo[] = [
//   {
//     balances: [
//       {
//         token: {
//           id: '1',
//           denom: 'ETH',
//           image: 'https://example.com/eth.png',
//           price_24h_change: '50.00',
//           latest_price: '3000.00',
//         },
//         usd_value: '15000.00',
//         balance: '5',
//       },
//     ],
//     integration: {
//       integration_id: 'integration_1',
//       platform: 'Platform Name',
//       stats: {
//         staking_stats: {
//           staking_type: 'Locked',
//         },
//         tvl: {
//           tokens: [
//             {
//               usd_value: '500000.00',
//             },
//           ],
//         },
//       },
//     },
//   },
// ]

const RevampPortfolioManager: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage()

  const [tier, setTier] = useState<string>(ETierRange.day)

  const [networks, setNetworks] = useState<string[]>([])
  // const [userStakingAssets, setUserStakingAssets] = useState<IAssetInfo[]>(
  //   sampleUserStakingAssets,
  // )

  const [loading, setLoading] = useState<boolean>(false)
  const [isOpenNetworkModal, setOpenNetworkModal] = useState<boolean>(false)

  const user = useAppSelector(state => state.user)
  const { walletAddress, mainAppToken } = user

  const chains = useMemo(() => {
    const storageNetworks =
      StorageService.get(STORAGE_NETWORK_KEY) ||
      PORTFOLIO_NETWORKS.map(n => n.code)
    return storageNetworks
  }, [networks])

  const {
    data: userAssetsRes,
    refetch: fetchUserAssetsInfo,
    isFetching: isFetchingUserAssets,
  } = useFetchUserAssetsQuery(
    {
      chains,
      wallet: walletAddress,
    },
    {
      skip: !walletAddress || !mainAppToken,
      refetchOnMountOrArgChange: true,
    },
  )

  const {
    data: userChartData,
    refetch: fetchChartDataInfo,
    isFetching: isFetchingUserChartData,
  } = useFetchUserAssetsTimeSeriesQuery(
    {
      tierName: tier,
      chains,
      wallet: walletAddress,
    },
    {
      skip: !walletAddress || !mainAppToken,
      refetchOnMountOrArgChange: true,
    },
  )

  const userAssetData = useMemo(() => {
    if (userAssetsRes) {
      const { tokenAssets } = userAssetsRes
      return cloneDeep(tokenAssets)
    }
    return []
  }, [userAssetsRes])

  const chartData = useMemo(() => cloneDeep(userChartData), [userChartData])

  const handleChangeNetworks = (networks: string[]) => {
    setNetworks(networks)
    fetchUserAssets('1d', false, networks)
    setOpenNetworkModal(false)
  }

  const closeNetworkModal = () => {
    setOpenNetworkModal(false)
  }

  // const handleSearchBundle = (wallets: IWallet[]) => {
  //   setSearchParams({ active: 'bundled' })
  //   fetchBundleAssets()
  // }

  // const fetchBundleAssets = async (
  //   tier = '1d',
  //   isSkipLoading = false,
  //   defaultNetworks: string[] = [],
  // ) => {
  //   let storageNetworks =
  //     StorageService.get(STORAGE_NETWORK_KEY) ||
  //     PORTFOLIO_NETWORKS.map(n => n.code)

  //   const rawbundledWallets = StorageService.get('bundledWallets') as IWallet[]
  //   if (!rawbundledWallets || !rawbundledWallets.length) {
  //     searchParams.delete('active')
  //     setSearchParams(searchParams)
  //     return
  //   }

  //   const bundledWallets = rawbundledWallets.map(wallet => wallet.address)

  //   let queryNetworks = storageNetworks
  //   if (defaultNetworks.length) {
  //     queryNetworks = defaultNetworks
  //   }

  //   try {
  //     setLoading(!isSkipLoading)
  //     // Get all assets
  //     // let assets = await portfolioService.getBundle(bundledWallets)

  //     // // Wallet token assets
  //     // let userAssets = assets.walletToken.filter(
  //     //   (i: IAssetOverview) => i && i.token,
  //     // ) // FIXME: temporary filter only Tokens
  //     // const userAssets = sampleUserAssets
  //     // setUserAssets(userAssets)

  //     // Wallet staking assets
  //     // setUserStakingAssets(assets.staking)
  //     setUserStakingAssets(sampleUserStakingAssets)

  //     // Wallet token assets chart
  //     // let assetsChart = await portfolioService.getBundlePortfolioChart(
  //     //   bundledWallets,
  //     //   queryNetworks,
  //     //   tier,
  //     // )
  //     // let assetsChart = sampleAssetsChart
  //     // setUserAssetsChart(assetsChart)
  //   } catch (e) {
  //     console.log('Error ', e)
  //     messageApi.error('Oops, unexpected error!')
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const fetchUserAssets = async (
    tier = '1d',
    isSkipLoading = false,
    defaultNetworks: string[] = [],
  ) => {
    let storageNetworks =
      StorageService.get(STORAGE_NETWORK_KEY) ||
      PORTFOLIO_NETWORKS.map(n => n.code)

    let queryNetworks = storageNetworks
    if (defaultNetworks.length) {
      queryNetworks = defaultNetworks
    }

    try {
      setLoading(!isSkipLoading)
      // Get all assets
      // let assets = await portfolioService.getUserAssets(
      //   [activeAddress],
      //   queryNetworks,
      // )
      // // Wallet token assets
      // let userAssets = assets.walletToken.filter(
      //   (i: IAssetOverview) => i && i.token,
      // ) // FIXME: temporary filter only Tokens
      // const userAssets = sampleUserAssets
      // setUserAssets(userAssets)
      setTier(tier)
      fetchUserAssetsInfo()
      fetchChartDataInfo()

      // Wallet staking assets
      // setUserStakingAssets(assets.staking)
      // setUserStakingAssets(sampleUserStakingAssets)

      // Wallet token assets chart
      // let assetsChart = await portfolioService.getUserTotalAssetsChart(
      //   [activeAddress],
      //   queryNetworks,
      //   tier,
      // )
      // let assetsChart = sampleAssetsChart
      // setUserAssetsChart(assetsChart)
    } catch (e) {
      console.log('Error ', e)
      messageApi.error('Oops, unexpected error!')
    } finally {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   if (activeAddress) {
  //     if (activeAddress === 'bundled') {
  //       fetchBundleAssets()
  //     } else {
  //       fetchUserAssets()
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeAddress])

  return (
    <div className="page-wrapper" style={{ width: '100%' }}>
      {/* {loading || isFetchingUserAssets || isFetchingUserChartData ? (
        <Loading />
      ) : null} */}
      <PageHeader
        onOpenNetwork={() => setOpenNetworkModal(true)}
        // onBundled={handleSearchBundle}
      />
      <AssetsOverview
        assets={userAssetData}
        assetsChart={chartData ?? null}
        // onFilter={fetchUserAssets}
        onFilter={filter => setTier(filter)}
      />
      {userAssetData.length > 0 && <WalletOverview assets={userAssetData} />}

      {/* {userStakingAssets.length > 0 && (
        <StakingAssets assets={userStakingAssets} />
      )} */}

      <NetworksModal
        open={isOpenNetworkModal}
        onSave={handleChangeNetworks}
        onClose={closeNetworkModal}
      />
      {contextHolder}
    </div>
  )

  // <Welcome />
}

export default RevampPortfolioManager
