import { Outlet } from "react-router-dom";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import SidebarMobile from "../../components/layout/SidebarMobile";

const MasterLayout = ({children}) => {
  return (
    <div className="primary-container">
      <div className="body-wrapper">
        <Sidebar />
        <main className="content">
          <Header />
          {/* <Outlet /> */}
          {children}
          <SidebarMobile />
        </main>
      </div>
    </div>
  );
};

export default MasterLayout;
