//@ts-nocheck
import { useState } from "react";
import { useAccount, useConnect, useSignMessage, useSwitchChain } from "wagmi";
import { useWallet } from "@solana/wallet-adapter-react";
import { PhantomWalletName } from "@solana/wallet-adapter-wallets";
// import { useAuth } from "@/providers/AuthProvider";
// import { useLogout } from "@/web3/hook/useLogout";
import { WalletConnect, sleep } from "../../../../constants/pool";
import {
  connectAndSignIn,
  connectAndSignInSwitchNetwork,
  getSortedConnectorsAndChainId,
} from "../../../../utils/web3";
import { networks, NETWORKS_NAME } from "../../../../utils/network";

export const useWalletFunctions = () => {
  const { address, isConnected } = useAccount();
  const { connectors, connectAsync } = useConnect();
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [isLoadingConnect, setIsLoadingConnect] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openSwitchNetwork, setOpenSwitchNetwork] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  // const { logout } = useLogout();
  const { logout } = () => {};
  const { connect: solConnect, disconnect, select, connected } = useWallet();
  // const { messageSignature, handleLogin } = useAuth();
  const { messageSignature, handleLogin } = {
    messageSignature: '',
    handleLogin: () => {}
  };
  const { signMessageAsync } = useSignMessage();
  const { switchChainAsync } = useSwitchChain();

  const { sortedConnectors, selectedChainId } = getSortedConnectorsAndChainId(
    connectors,
    networks,
    selectedNetwork
  );

  const handleClose = () => {
    setSelectedNetwork(null);
    setIsLoadingConnect(false);
    setSelectedConnector(null);
    setOpenModal(false);
    setOpenSwitchNetwork(false);
    setOpenDisconnect(false);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleLogoutSol = () => {
    disconnect();
    handleClose();
  };

  const handleSolConnectWallet = async () => {
    try {
      select(PhantomWalletName || selectedConnector);
      await solConnect();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleNetworkClick = (network) => {
    if (
      (isConnected && network === NETWORKS_NAME.SOLANA) ||
      (connected && network !== NETWORKS_NAME.SOLANA)
    ) {
      handleLogout();
      handleLogoutSol();
    }
    setSelectedNetwork(network);
  };

  const handleConnectorClick = (connector) => {
    setSelectedConnector(connector);
  };

  const handleConnectWallet = async (connectorName) => {
    setIsLoadingConnect(true);
    try {
      if (selectedNetwork === NETWORKS_NAME.SOLANA) {
        handleSolConnectWallet();
      }
      if (connectorName === WalletConnect) {
        setOpenModal(false);
      }
      await connectAndSignIn(
        connectorName,
        selectedNetwork,
        selectedChainId,
        sortedConnectors,
        connectAsync,
        signMessageAsync,
        handleLogin,
        messageSignature,
        address
      );
    } catch (error) {
      logout();
      handleClose();
      console.error("Error connecting to wallet:", error);
    } finally {
      handleClose();
    }
  };
  const handleSwitchNetwork = async () => {
    setIsLoadingConnect(true);
    try {
      await connectAndSignInSwitchNetwork(
        selectedNetwork,
        selectedChainId,
        messageSignature,
        address,
        handleLogin,
        switchChainAsync,
        signMessageAsync,
        sleep
      );
    } catch (error) {
      handleClose();
      console.error("Error connecting to wallet:", error);
    } finally {
      handleClose();
    }
  };

  return {
    selectedNetwork,
    selectedConnector,
    openModal,
    openSwitchNetwork,
    openDisconnect,
    handleClose,
    handleLogout,
    handleNetworkClick,
    handleConnectorClick,
    handleConnectWallet,
    handleSwitchNetwork,
    setOpenModal,
    setOpenSwitchNetwork,
    setOpenDisconnect,
    setSelectedNetwork,
    setSelectedConnector,
    sortedConnectors,
    selectedChainId,
    isLoadingConnect,
    handleLogoutSol,
  };
};
