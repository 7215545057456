export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.53345 13.2666V6.16668L8.0001 2.80388L12.4667 6.16668V13.2666H9.136V9.07178H6.8642V13.2666H3.53345Z"
        fill="#F1EDF2"
        fillOpacity="0.6"
      />
    </svg>
  );
};
