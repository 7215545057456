import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'

export enum IFilterStatus {
  ALL = 'All',
  PARTICIPATION = 'Participation',
  INVESTMENT = 'Investment',
  TGE = 'TGE',
  COMPLETED = 'Completed',
}
interface FilterStatusMySalesProps {
  status: IFilterStatus
  onChange: (status: IFilterStatus) => void
  handleTabChange: (index: IFilterStatus) => void
}

export const getStatusStyleFilter = (status: string) => {
  switch (status) {
    case IFilterStatus.TGE:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#63EA71',
      }
    case IFilterStatus.COMPLETED:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#63EA71',
      }
    case IFilterStatus.INVESTMENT:
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#fe7f30',
      }
    case IFilterStatus.PARTICIPATION:
      return {
        backgroundColor: 'rgba(244, 253, 171, 0.1)',
        color: '#F4FDAB',
      }
    case IFilterStatus.ALL:
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#fff',
      }
    default:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
  }
}

export const getStatusStyleFilterHeader = (status: string) => {
  switch (status) {
    case IFilterStatus.TGE:
      return {
        backgroundColor: '#e4f1e5',
        color: '#63EA71',
      }
    case IFilterStatus.INVESTMENT:
      return {
        backgroundColor: '#f3e6dd',
        color: '#fe7f30',
      }
    case IFilterStatus.PARTICIPATION:
      return {
        backgroundColor: '#f4fdab',
        color: '#fcbf62',
      }
    case IFilterStatus.ALL:
      return {
        backgroundColor: '#000',
        color: '#fff',
      }
    default:
      return {
        backgroundColor: 'transparent',
        color: '#000',
      }
  }
}

const FilterStatusMySales: React.FC<FilterStatusMySalesProps> = ({
  status,
  onChange,
  handleTabChange,
}) => {
  const activeTabBorder = '0.4px solid rgba(255, 255, 255, 0.16)'
  const isDown800 = useMediaQuery('(max-width:800px)')

  const statusArray = [
    IFilterStatus.ALL,
    IFilterStatus.PARTICIPATION,
    IFilterStatus.INVESTMENT,
    IFilterStatus.TGE,
  ]

  return (
    <Grid
      container
      borderRadius={'25px'}
      bgcolor={'#1c232a'}
      width={isDown800 ? '100%' : 'max-content'}
      gap={1}
      justifyContent={isDown800 ? 'start' : 'center'}
      alignItems={isDown800 ? 'start' : 'center'}
      display={'flex'}
      height={!isDown800 ? '40px' : '100%'}
      paddingX={'10px'}
      marginBottom={'16px'}
      className="participation-type-tabs"
    >
      {statusArray.map((item, index) => (
        <Grid
          key={index}
          display={'flex'}
          width={isDown800 ? '88px' : '88px'}
          height={'28px'}
          justifyContent={'center'}
          alignItems={'center'}
          border={status === item ? activeTabBorder : 'none'}
          onClick={() => {
            handleTabChange(item)
            onChange(item)
          }}
          sx={{
            cursor: 'pointer',
            borderRadius: '25px',
            ...(status === item
              ? getStatusStyleFilter(item)
              : {
                  color: '#B1B4B3',
                }),
          }}
        >
          <Typography
            fontSize={'12px'}
            fontWeight={500}
            lineHeight={'22.5px'}
            fontFamily={'Inter'}
            sx={{
              color:
                status === item ? getStatusStyleFilter(item).color : '#B1B4B3',
            }}
          >
            {item}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default FilterStatusMySales
