import {
  ArbitrumIcon,
  AvalancheIcon,
  BNBIcon,
  EthereumIcon,
  SolanaIcon,
} from '../../../assets'

export function getNetworkIconByChain(chain: string) {
  switch (chain) {
    case 'ETH':
      return EthereumIcon
    case 'BSC':
      return BNBIcon
    case 'BSC_TESTNET':
      return BNBIcon
    case 'Solana':
      return SolanaIcon
    case 'AvalancheC':
      return AvalancheIcon
    case 'Arbitrum':
      return ArbitrumIcon
    default:
      return BNBIcon
  }
}
