import { Box, Typography, useMediaQuery } from '@mui/material'
import { GoogleIcon } from '../../assets'
import ConnectButton from '../../components/Buttons/ConnectButton'
import GreenButton from '../../components/GreenButton'
import SignatureModal from '../../components/SignatureModal'
import SignInWithGoogleModal from '../../components/SignInWithGoogleModal'
import { useState } from 'react'
import { useAccount } from 'wagmi'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useParticipationEntryPoint } from '../../Context'
import { setLoginMethod, setWalletAddress } from '../../redux/slices/userSlice'

const AccountLogin = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const [openGoogleModal, setOpenGoogleModal] = useState(false)
  const [openSignautureModal, setOpenSignautureModal] = useState(false)
  const dispatch = useAppDispatch()
  const { address } = useAccount()
  const { open: openWeb3Modal } = useWeb3Modal()
  const { setParticipationEntryPoint } = useParticipationEntryPoint()
  const user = useAppSelector(state => state.user)

  const handleConect = () => {
    dispatch(setWalletAddress(''))
    dispatch(setLoginMethod('metamask'))
    setOpenSignautureModal(false)
    openWeb3Modal()
    setParticipationEntryPoint('Header')
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isDown1000 ? 'column' : 'row',
        justifyContent: 'center',
        borderRadius: '12px',
        padding: isDown1000 ? '16px' : '24px',
        width: 'max-content',
        overflowWrap: 'anywhere',
        gap: '12px',
        alignItems: 'center',
        margin:'0 auto'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '20px',
          alignSelf: 'center',
          textAlign: 'center',
          zIndex: 3,
        }}
      >
        <Typography
          sx={{
            paddingBottom: '40px',
            fontSize: isDown600 ? '24px' : '32px',
            fontWeight: 600,
            color: '#fff',
            whiteSpace: 'nowrap',
            fontFamily: 'Hellix',
          }}
        >
          Login to Your Account
        </Typography>
        <GreenButton
          IconStart={() => <img src={GoogleIcon} alt="google icon" />}
          text="Sign in with Google"
          br="24px"
          fs={15}
          fw={400}
          fm="Inter"
          lh="22.5px"
          p="8px 14px"
          h="38px"
          w="max-content"
          onClick={() => {
            setOpenGoogleModal(true)
          }}
          disabled={!!user.email}
          sx={{ textTransform: 'none', mb: '20px' }}
        />
        <ConnectButton
          text="Connect Wallet"
          onClick={handleConect}
          disabled={!!address}
        />
      </Box>
      <SignatureModal
        open={openSignautureModal}
        setOpen={() => setOpenSignautureModal(false)}
      />

      <SignInWithGoogleModal
        open={openGoogleModal}
        onClose={() => setOpenGoogleModal(false)}
      />
    </Box>
  )
}
export default AccountLogin
