import { api } from '../../api'
import { IIdo } from '../project'

export interface IReferrerResponse {
  ido: IIdo
  project: { name: string; logoUrl: string }
  referralCount: number
  multiplier: number
}

export const referrals = api.injectEndpoints({
  endpoints: build => ({
    fetchUserReferrals: build.query<IReferrerResponse[], void>({
      query: () => `participation/user/referrals`,
    }),
    fetchUserReferralsWithSearch: build.query<
      IReferrerResponse[],
      { name?: string }
    >({
      query: params => {
        const queryParams = params?.name
          ? `?name=${encodeURIComponent(params.name)}`
          : ''
        return `participation/user/referrals${queryParams}`
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchUserReferralsQuery,
  useFetchUserReferralsWithSearchQuery,
} = referrals
