import {
  BSC_CHAIN_ID,
  BSC_SCAN_BASE_URL,
  ETHER_SCAN_BASE_URL,
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  POL_SCAN_BASE_URL,
  SOLANA_CHAIN_ID,
  SOLANA_ENV,
  SOL_SCAN_URL,
  USDC_BSC_ADDRESS,
  USDC_ETH_ADDRESS,
  USDC_POLYGON_ADDRESS,
  USDC_SOLANA_ADDRESS,
  USDT_BSC_ADDRESS,
  USDT_ETH_ADDRESS,
  USDT_POLYGON_ADDRESS,
  USDT_SOLANA_ADDRESS,
} from "../utils/network";

export const WalletConnect = 'WalletConnect';
export const MetaMask = 'MetaMask'

export enum Status {
  UPCOMING = "UPCOMING",
  INPROGRESS = "INPROGRESS",
  COMPLETED = "COMPLETED",
  LIVEANDUPCOMING = "LIVEANDUPCOMING",
}
export enum PoolType {
  XBORG = 1,
  PUBLIC,
  COMPLETED,
}

export enum TokenAddress {
  USDT = "USDT",
  USDC = "USDC",
}

export const NETWORK_AVAILABLE = {
  ETH: "eth",
  BSC: "bsc",
  POLYGON: "pol",
  SOL: 'sol',
};

export const NETWORK_AVAILABLE_MAP = {
  [ETH_CHAIN_ID]: NETWORK_AVAILABLE.ETH,
  [POLYGON_CHAIN_ID]: NETWORK_AVAILABLE.POLYGON,
  [SOLANA_CHAIN_ID]: NETWORK_AVAILABLE.SOL,
  [BSC_CHAIN_ID]: NETWORK_AVAILABLE.BSC,
};

export const NETWORK_NAME_MAP = {
  [NETWORK_AVAILABLE.ETH]: 'Ethereum',
  [NETWORK_AVAILABLE.POLYGON]: 'Polygon',
  [NETWORK_AVAILABLE.SOL]: 'Solana',
  [NETWORK_AVAILABLE.BSC]: 'Binance Smart Chain',
};

export function getNameNetworkByChainId(chainId: string) {
  switch (chainId) {
    case ETH_CHAIN_ID:
      return "ETHEREUM";
    case POLYGON_CHAIN_ID:
      return "POLYGON";
    case BSC_CHAIN_ID:
      return "BSC";
    case SOLANA_CHAIN_ID:
        return "SOL";
    default:
      return "";
  }
}

const tokenNetworkMappingUSDT = {
  [USDT_SOLANA_ADDRESS.toLowerCase()]: "USDT",
  [USDT_POLYGON_ADDRESS.toLowerCase()]: "USDT",
  [USDT_BSC_ADDRESS.toLowerCase()]: "USDT",
  [USDT_ETH_ADDRESS.toLowerCase()]: "USDT",
};

const tokenNetworkMappingUSDC = {
  [USDC_SOLANA_ADDRESS.toLowerCase()]: "USDC",
  [USDC_POLYGON_ADDRESS.toLowerCase()]: "USDC",
  [USDC_BSC_ADDRESS.toLowerCase()]: "USDC",
  [USDC_ETH_ADDRESS.toLowerCase()]: "USDC",
};

export function getTokenNetworkByTokenAddress(tokenAddress: string) {
  
  // return tokenNetworkMappingUSDT[tokenAddress as string] || tokenNetworkMappingUSDC[tokenAddress];
}

export const sleep = async (second: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), Math.min(second * 1000, 3600 * 24 * 1000));
  });
};

export function isTBA(value: any) {
  return value === "TBA" || value === "tba" || !value;
}

export function getExplorerTransactionUrl(chainId: string, transactionHash: string) {
  switch (chainId) {
    case POLYGON_CHAIN_ID:
      return `${POL_SCAN_BASE_URL}/tx/${transactionHash}`;
    case ETH_CHAIN_ID:
      return `${ETHER_SCAN_BASE_URL}/tx/${transactionHash}`;
    case BSC_CHAIN_ID:
      return `${BSC_SCAN_BASE_URL}/tx/${transactionHash}`;
    default:
      return `${SOL_SCAN_URL}/tx/${transactionHash}?cluster=${SOLANA_ENV}`;
  }
}