import { ArrowLeftOutlined } from '@ant-design/icons'
import arrowDownIcon from '../../assets/revamp/icons/arrow_down.svg'
import arrowUpIcon from '../../assets/revamp/icons/arrow_up.svg'
import chevronIcon from '../../assets/revamp/icons/chevron.svg'
import Icon from '../../components/common/Icon'
import {
  ETierRange,
  ITokenAssetInfo,
  ITokenTimeSeries,
} from '../../services/modules/portfolio-manager'
import { numberFormat } from '../../utils/function'
import TotalAssetsChart from './TotalAssetsChart'

interface TokenInfomationProps {
  asset: ITokenAssetInfo
  tokenDataChart: ITokenTimeSeries | null
  currentFilter: string
  handleChangeChartTier: (event: React.ChangeEvent<HTMLSelectElement>) => void
  backToHolding: (id: string | number) => void
}

const TokenInfomation: React.FC<TokenInfomationProps> = props => {
  const {
    asset,
    tokenDataChart,
    currentFilter,
    handleChangeChartTier,
    backToHolding,
  } = props

  const chartChangeRate = () => {
    if (!asset || !tokenDataChart) return null

    const { timeSeries } = tokenDataChart
    const timeseriesValues = Object.values(timeSeries || [])

    const firstValue = timeseriesValues[0]
    const lastValue = timeseriesValues[timeseriesValues.length - 1]
    const isIncrease = lastValue - firstValue > 0
    const changePercentage =
      Math.abs((lastValue - firstValue) / firstValue) * 100

    return (
      <span className="change">
        <Icon src={isIncrease ? arrowUpIcon : arrowDownIcon} />
        &nbsp;
        {
          <b>
            {changePercentage > 0.01
              ? numberFormat(changePercentage, 2, 2)
              : '<0.01'}
            %
          </b>
        }
      </span>
    )
  }

  return (
    <div className="graph">
      <div className="graph--header">
        <aside>
          <b>
            {/* {asset.references && <ArrowLeftOutlined onClick={backToHolding} />}{' '} */}
            {false && (
              <ArrowLeftOutlined
                onClick={() => backToHolding(asset?.token?.id ?? '')}
              />
            )}{' '}
            &nbsp;
            {asset.token.denom}
          </b>
          <span>
            ${numberFormat(parseFloat(asset.token.latest_price ?? ''), 2, 2)}
          </span>
          <span>{chartChangeRate()}</span>
        </aside>
        <span className="switch">
          <select
            name=""
            id=""
            style={{ backgroundImage: `url(${chevronIcon})` }}
            onChange={handleChangeChartTier}
          >
            <option value={ETierRange.day}>1D</option>
            <option value={ETierRange.week}>1W</option>
            <option value={ETierRange.month}>1M</option>
            <option value={ETierRange.year}>1Y</option>
          </select>
        </span>
      </div>
      <div style={{ height: 197 }}>
        {tokenDataChart ? (
          <TotalAssetsChart
            data={tokenDataChart}
            xAxisFormat={currentFilter === '1d' ? 'hh:mm' : 'D MMM'}
          />
        ) : null}
      </div>
    </div>
  )
}

export default TokenInfomation
