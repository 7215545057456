export default {
  /**
   * Set storage
   *
   * @param {String} key
   * @param {*} value
   */
  set(key, value) {
    if (['number', 'string'].indexOf(typeof value) === -1) {
      value = JSON.stringify(value)
    }

    localStorage.setItem(key, value)
  },

  /**
   * Get storage
   *
   * @param {String} key
   * @returns {*}
   */
  get(key) {
    const value = localStorage.getItem(key)

    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  },

  /**
   * Remove storage
   *
   * @param {String} key
   */
  remove(key) {
    localStorage.removeItem(key)
  },

  /**
   * Remove storage
   *
   * @param {Array} list
   */
  removeMultiple(list) {
    list.forEach((key) => {
      localStorage.removeItem(key)
    })
  },

  /**
   * Destroy all storage
   */
  destroy() {
    localStorage.clear()
  },
}
